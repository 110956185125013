import React, { useEffect, useState } from "react";
import vuiLogo from '../assets/loginPage/VUILogo.png';
import bannerImage from '../assets/loginPage/bannerImage1.png';
import logoCOE from '../assets/logoWhiteNew.png';
import successIcon from "../assets/Common/successIcon.svg"

import LoginBox from "../components/login/LoginBox";

import '../styles/loginStyles/login.scss'
import ForgetBox from "../components/login/ForgetBox";
import { refreshAllProperties } from "../services/PropertyServices";
import { refreshAllBlogs } from "../services/BlogServices";
import { refreshAllAmenities } from "../services/AmenitiesServices";
import { refreshAllUsers } from "../services/UserServices";
import { refreshAllLocation } from "../services/LocationServices";

function LoginPage({ setLogin }) {

    const [loginForm, setLoginForm] = useState(true)
    const [successNotification, setSuccessNotification] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [isPreWordDone, setIsPreWordDone] = useState(false)

    // checking any one login or not
    useEffect(() => {

        if (typeof sessionStorage !== 'undefined' && localStorage !== null) {
            // Iterate through localStorage keys
            if (sessionStorage.getItem('egdeetaroprocdnauiv') !== null) {

                setIsLoading(true)
                setIsPreWordDone(true)

                refreshAllProperties()
                refreshAllBlogs()
                setTimeout(() => {
                    refreshAllAmenities()
                    refreshAllUsers()
                    refreshAllLocation()
                }, 1000);

                setTimeout(() => {
                    setLogin(true)
                }, 1200);
            }
            else {
                setIsPreWordDone(true)
            }
        }
        else {
            
            setIsPreWordDone(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {isPreWordDone ?
                isLoading
                    ?
                    <div className="loadingScreen" style={{height: '100vh'}}>
                        <div className="waitingText" style={{fontSize: '30px', lineHeight: '30px', top: '42.5%'}}>Loading...</div>
                        <div className="progress" style={{width: '40%'}}>
                            <div className="color"></div>
                        </div>
                    </div>
                    :
                    <div className="loginModule">
                        <div className="upperNav">
                            <div className="innerSpaceUpperNav">
                                <span>Powered By</span>
                                <img src={vuiLogo} alt="vuiLogo" />
                            </div>
                        </div>
                        <div className="mainSection">
                            <div className="backgroundImage">
                                <img src={bannerImage} alt="bannerImage" />
                            </div>
                            <div className="centerBoxOfMainSection">
                                <div className="innerDisplayArea">
                                    <div className="leftSideBranding">
                                        <div className="logoShow">
                                            <img src={logoCOE} alt="logoCOE" />
                                        </div>
                                        <div className="headingOfSection">
                                            Personalized Workspace Solutions for All Professionals
                                        </div>
                                        <div className="subTextOfSection">
                                            A Prestigious Institution of Intellectual Curiosity, Academic Excellence, and Global Impact, Pioneering the Frontiers of Knowledge and Shaping Future Leaders at Columbia University
                                        </div>
                                    </div>
                                    <div className="rightSideForm">
                                        {loginForm ?
                                            <LoginBox setLogin={setLogin} setLoginForm={setLoginForm}></LoginBox>
                                            :
                                            <ForgetBox setLoginForm={setLoginForm} setSuccessNotification={setSuccessNotification}></ForgetBox>
                                        }
                                        {successNotification ?
                                            <div className="smallNotificationForm">
                                                <div className="mainBoxNotificationBox">
                                                    <div className="iconOfNotificationBox"><img src={successIcon} alt="successIcon" /></div>
                                                    <div className="textBoxOfNotificationBox">
                                                        <div className="headingOfNotification">
                                                            Password successfully changed
                                                        </div>
                                                        <div className="subTextOfNotification">
                                                            You password is successfully reset. Now you can login to your portal. Just enter your email and password here to login.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                : <div className="loadingScreen"></div>
            }

        </>
    )
}


export default LoginPage;