import React, { useEffect, useRef, useState } from "react";
import "../styles/blogs/addBlogs.scss"

import InputSimpleBox from "../components/Forms/InputSimpleBox";
import CkEditorBox from "../components/Forms/CkEditorBox";

import changesIcon from "../assets/Common/changesIcon.svg";
import successIcon from "../assets/Common/successIcon.svg"
import { useNavigate, useParams } from "react-router-dom";
import { bannerSelectionCountWithException, deleteBlogImage, editorChoiceCountWithException, getBlogById, getBlogIndexById, inStreamCountWithException, refreshAllBlogs } from "../services/BlogServices";

import backButton from '../assets/Common/backButton.svg'
import ShowImageNormally from "../components/Forms/ShowImageNormally";
import axios from "axios";
import InputDropDownBox from "../components/Forms/InputDropDownBox";
import UploadBox from "../components/Forms/UploadBox";
import InputSimpleBoxDate from "../components/Forms/InputSimpleBoxDate";



export default function EditBlog() {


    const navigate = useNavigate();
    const [currentImageIndex, setCurrentImageIndex] = useState(1)

    const [titleState, setTitleState] = useState("")
    const [dateState, setDateState] = useState("")
    const [authorNameState, setAuthorNameState] = useState("")

    const [bannerImageState, setBannerImageState] = useState(true)
    const [authorImageState, setAuthorImageState] = useState(true)

    const image1Ref = useRef();
    const image2Ref = useRef();

    const authorNameRef = useRef();
    const authorDesignationRef = useRef();

    const titleRef = useRef();
    const shortDescriptionRef = useRef();
    const categoryRef = useRef();

    const placementRef = useRef()
    const placementDisplayValueRef = useRef()

    const tagRef = useRef();
    const dateRef = useRef();

    const descriptionRef = useRef();
    const descriptionStore = useRef();

    const metaTitleRef = useRef();
    const metaDescriptionRef = useRef();

    const firstValueStored = useRef({
        authorNameRef: '',
        authorDesignationRef: '',
        titleRef: '',
        shortDescriptionRef: '',
        categoryRef: '',
        placementRef: '',
        placementDisplayValueRef: '',
        tagRef: '',
        dateRef: '',
        descriptionRef: '',
        descriptionStore: '',
        metaTitleRef: '',
        metaDescriptionRef: ''
    })

    const [writerImages, setWriterImages] = useState()
    const [blogImage, setBlogImage] = useState()

    const blogImageId = useRef()
    const writerImageId = useRef()


    const [confirmationScreen, setConfirmationScreen] = useState(false)
    const [confirmedScreen, setConfirmedScreen] = useState(false)

    const [error, setError] = React.useState({
        authorName: "",
        authorDesignation: "",
        title: "",
        shortDescription: "",
        category: "",
        tag: "",
        date: "",
        metaTitle: "",
        metaDescription: "",
        placementSection: "",
        img1: '',
        img2: ''
    });

    const pushPhoto = (file, number) => {
        if (number === "1" || number === 1) {
            image1Ref.current = file;
        }
        if (number === "2" || number === 2) {
            image2Ref.current = file;
        }
    };

    // Introducing a parameter to refresh the UI
    const [dataLoaded, setDataLoaded] = useState(false)

    // getting Id from URL
    const { paramPropId } = useParams();

    useEffect(() => {

        // scrolling the windows up
        let centralWindow = document.querySelector(".centerPanel")
        centralWindow.scrollTo(0, 0);

        // getting data by ID and storing into temp var
        let getData = getBlogById(paramPropId)

        // setting all the normal Ref panel
        authorNameRef.current = getData.writer
        setAuthorNameState(getData.writer)
        authorDesignationRef.current = getData.writertitle

        titleRef.current = getData.title
        setTitleState(getData.title)
        shortDescriptionRef.current = getData.subtitle
        categoryRef.current = getData.category

        tagRef.current = arrayToString(getData.type)
        dateRef.current = formatDate(getData.date)
        setDateState(formatDate(getData.date))

        descriptionRef.current = getData.description
        descriptionStore.current = getData.description

        metaTitleRef.current = getData.meta_title
        metaDescriptionRef.current = getData.meta_description

        if (getData.ediorpick === "YES") {
            placementRef.current = "editorPick"
            placementDisplayValueRef.current = "Editor Pick Section"
        }
        else if (getData.bannerselection === "YES") {
            placementRef.current = "bannerSelection"
            placementDisplayValueRef.current = "Banner Of Page"
        }
        else if (getData.instream === "YES") {
            placementRef.current = "inStream"
            placementDisplayValueRef.current = "In Stream"
        }
        else {
            placementRef.current = "normal"
            placementDisplayValueRef.current = "Normal"
        }

        // setting Images to the respective ref
        getData?.images.forEach(element => {
            if (element.title === 'banner') {
                setBlogImage("https://data.thecorporatedge.com/cordocs/" + element?.url)
                blogImageId.current = element?.id
            }
            else {
                setWriterImages("https://data.thecorporatedge.com/cordocs/" + element?.url)
                writerImageId.current = element?.id
            }
        });

        // setting Previous Value
        firstValueStored.current = {
            authorNameRef: authorNameRef.current,
            authorDesignationRef: authorDesignationRef.current,
            titleRef: titleRef.current,
            shortDescriptionRef: shortDescriptionRef.current,
            categoryRef: categoryRef.current,
            placementRef: placementRef.current,
            placementDisplayValueRef: placementDisplayValueRef.current,
            tagRef: tagRef.current,
            dateRef: dateRef.current,
            descriptionRef: descriptionRef.current,
            descriptionStore: descriptionStore.current,
            metaTitleRef: metaTitleRef.current,
            metaDescriptionRef: metaDescriptionRef.current,
        }

        // getting Index position of property
        setCurrentImageIndex(getBlogIndexById(paramPropId))
        setDataLoaded(true)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // converting array to string
    const arrayToString = (array) => {
        return array.join(", ");
    }

    // function to format date
    const formatDate = (inputDate) => {
        const date = new Date(inputDate);
        const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
        const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
        const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
        const suffix = day === '1' || day === '21' || day === '31' ? 'st' : day === '2' || day === '22' ? 'nd' : day === '3' || day === '23' ? 'rd' : 'th';
        return `${day}${suffix} ${month} ${year}`;
    }


    // Confirm before saving data
    const confirmBeforeSavingData = () => {
        if (validateInputs()) {
            setConfirmationScreen(true)
        }
    }

    // Confirm before saving data
    const cancelBeforeSavingData = () => {
        setConfirmationScreen(false)
    }

    // updating data
    const savingData = () => {
        setConfirmationScreen(false)
        setConfirmedScreen(true)

        pushData()
    }



    const validateInputs = () => {
        let errors = {};

        if (!authorNameRef.current.value) errors.authorName = "Author name is required";
        if (!authorDesignationRef.current.value) errors.authorDesignation = "Author designation is required";
        if (!titleRef.current.value) errors.title = "Title is required";
        if (!shortDescriptionRef.current.value) errors.shortDescription = "Short description is required";
        if (!categoryRef.current) errors.category = "Category is required";
        if (!tagRef.current.value) errors.tag = "Tag is required";
        if (tagRef.current.value && tagRef.current.value.split(",").length > 2) errors.tag = "Only 2 Tags allowed";
        if (!dateRef.current.value) errors.date = "Date is required";
        if (!metaTitleRef.current.value) errors.metaTitle = "Meta title is required";
        if (!metaDescriptionRef.current.value) errors.metaDescription = "Meta description is required";


        if (!bannerImageState && !image1Ref.current) errors.img1 = "Banner Image is required";
        if (!authorImageState && !image2Ref.current) errors.img2 = "Author Image is required";

        const placementErrors = {
            editorPick: "Only 2 Editor Pick Section's Blogs allowed",
            bannerSelection: "Only 3 Banner's blogs allowed",
            inStream: "Only 1 In Stream's blogs allowed"
        };
        const placementCounts = {
            editorPick: editorChoiceCountWithException(paramPropId),
            bannerSelection: bannerSelectionCountWithException(paramPropId),
            inStream: inStreamCountWithException(paramPropId)
        };
        const placementLimits = {
            editorPick: 1,
            bannerSelection: 2,
            inStream: 0
        };
        if (placementCounts[placementRef.current] > placementLimits[placementRef.current]) {
            errors.placementSection = placementErrors[placementRef.current];
        }

        setError(errors);
        return Object.keys(errors).length === 0;
    };

    const pushData = async () => {
        const data = {
            title: titleRef.current.value,
            subtitle: shortDescriptionRef.current.value,
            type: tagRef.current.value.split(","),
            category: categoryRef.current,
            description: descriptionRef.current,
            ediorpick: "NO",
            bannerselection: "NO",
            instream: "NO",
            writer: authorNameRef.current.value,
            writertitle: authorDesignationRef.current.value,
            meta_title: metaTitleRef.current.value,
            meta_description: metaDescriptionRef.current.value,
            meta_tag: metaDescriptionRef.current.value,
            date: convertDate(dateRef.current.value),
            og_image: ""
        };

        data.ediorpick = placementRef.current === "editorPick" && editorChoiceCountWithException(paramPropId) < 2 ? "YES" : "NO";
        data.bannerselection = placementRef.current === "bannerSelection" && bannerSelectionCountWithException(paramPropId) < 3 ? "YES" : "NO";
        data.instream = placementRef.current === "inStream" && inStreamCountWithException(paramPropId) === 0 ? "YES" : "NO";


        const token = "Bearer " + sessionStorage.getItem('egdeetaroprocdnauiv');

        try {
            uploadAllImages(paramPropId)
            await axios({
                url: "https://data.thecorporatedge.com/CORManager/blog/update/" + paramPropId,
                method: "PUT",
                headers: {
                    authorization: token,
                },
                data: data,
            }).then(res => {
                setTimeout(() => {
                    refreshAllBlogs();
                    setTimeout(() => {
                        navigate("/blogs")
                    }, 1000)
                }, 2000);
            }).catch(() => alert('Unable to do this work now, Please try it again after some time.'));
        } catch (error) {
            console.error(error);
        }
    };

    const uploadAllImages = (dataId) => {
        if (!bannerImageState) {
            uploadImage(image1Ref.current, "banner", dataId, blogImageId.current);
        }
        setTimeout(() => {
            if (!authorImageState) {
                uploadImage(image2Ref.current, "writer", dataId, writerImageId.current);
            }
        }, 1000);
    };

    const uploadImage = (file, fileName, dataId, existingId) => {
        let formData = new FormData();
        let token = "Bearer " + sessionStorage.getItem("egdeetaroprocdnauiv");

        // Adding files to the formdata
        formData.append("file", file);
        formData.append("title", fileName);

        axios({
            // Endpoint to send files
            url: "https://data.thecorporatedge.com/CORManager/blog/image/" + dataId,
            method: "POST",
            headers: {
                // Add any auth token here
                authorization: token,
            },

            // Attaching the form data
            data: formData,
        }).then(() => {
            if (existingId) {
                deleteBlogImage(existingId)
            }
        })
    };

    const convertDate = (dateString) => {
        const dateParts = dateString.split(" ");
        const months = {
            January: "01",
            February: "02",
            March: "03",
            April: "04",
            May: "05",
            June: "06",
            July: "07",
            August: "08",
            September: "09",
            October: "10",
            November: "11",
            December: "12"
        };
        const day = dateParts[0].replace("th", "");
        const month = months[dateParts[1]];
        const year = dateParts[2];
        return `${year}-${month}-${day}`;
    };

    // remove image selection Box
    const removeBannerImage = () => {
        setBannerImageState(false)
    }

    const removeAuthorImage = () => {
        setAuthorImageState(false)
    }


    return (
        <>
            <div className="addBlogs lenis lenis-smooth">
                <div className="addBlogPanel">
                    {
                        dataLoaded ?
                            <>
                                <div className="headingTitle">
                                    <div className="backButton" onClick={() => navigate("/blogs")}>
                                        <img src={backButton} alt="backButton" />
                                        <span>Back</span>
                                    </div>
                                    <span>view your uploaded property</span>
                                </div>
                                <div className="dataTableStyles">
                                    <div className="entryOfTable">
                                        <div className="imageEntry">
                                            <span>{currentImageIndex + 1}.</span>
                                            <img src={blogImage} alt="bannerImage" />
                                        </div>
                                        <div className="nameEntry">
                                            {titleState}
                                        </div>
                                        <div className="locationEntry">
                                            {dateState}
                                        </div>
                                        <div className="locationEntry">
                                            {authorNameState}
                                        </div>
                                        <div className="modifyingOption">
                                            <div className="allButtons">
                                                <div className="button" style={{ background: "#14C21B" }} onClick={() => confirmBeforeSavingData()}>
                                                    <span>Save</span>
                                                    <img src={changesIcon} alt="editIcon" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="headingTitle">Upload your blog image</div>
                                <div className="imageUploadBlogSection">
                                    <div className="mainPhoto">
                                        {bannerImageState ?
                                            <ShowImageNormally
                                                labelText="photo 1*"
                                                imgNumber="1"
                                                imageRef={pushPhoto}
                                                preFilledImage={blogImage}
                                                clickAction={removeBannerImage}
                                            ></ShowImageNormally>
                                            :
                                            <UploadBox
                                                labelText="photo 1*"
                                                imgNumber="1"
                                                imageRef={pushPhoto}
                                            ></UploadBox>
                                        }
                                        {error.img1 && (
                                            <div className="errorBar" style={{ textAlign: "center" }}>{error.img1}</div>
                                        )}
                                    </div>
                                    <div className="authorDetailsBox">
                                        <div className="authorImage">
                                            {
                                                authorImageState ?
                                                    <ShowImageNormally
                                                        labelText="Author Image"
                                                        imgNumber="2"
                                                        preFilledImage={writerImages}
                                                        clickAction={removeAuthorImage}
                                                    ></ShowImageNormally>
                                                    :
                                                    <UploadBox
                                                        labelText="Author Image"
                                                        imgNumber="2"
                                                        imageRef={pushPhoto}
                                                    ></UploadBox>
                                            }
                                            {error.img2 && (
                                                <div className="errorBar">{error.img2}</div>
                                            )}
                                        </div>
                                        <div className="authorDetails">
                                            <InputSimpleBox
                                                labelText="Author name*"
                                                placeholder="Enter Author Name*"
                                                getRef={authorNameRef}
                                                priValue={firstValueStored.current.authorNameRef}
                                            ></InputSimpleBox>
                                            <InputSimpleBox
                                                labelText="Author designation*"
                                                placeholder="Enter Author Designation"
                                                getRef={authorDesignationRef}
                                                priValue={firstValueStored.current.authorDesignationRef}
                                            ></InputSimpleBox>
                                        </div>
                                    </div>
                                    <div className="midLine"></div>
                                    <div className="bottomLine"></div>
                                </div>
                                <div className="headingTitle">Update your blog information</div>
                                <div className={error.title ? "fullTextBox error" : "fullTextBox"}>
                                    <InputSimpleBox
                                        labelText="title/ blog heading (H1) "
                                        placeholder="Enter Blog Heading"
                                        getRef={titleRef}
                                        priValue={firstValueStored.current.titleRef}
                                    ></InputSimpleBox>
                                    {error.title && <div className="errorBar">{error.title}</div>}
                                </div>
                                <div className={error.shortDescription ? "fullTextBox error" : "fullTextBox"}>
                                    <InputSimpleBox
                                        labelText="short blog description (subtitle)"
                                        placeholder="Enter Short Description"
                                        getRef={shortDescriptionRef}
                                        priValue={firstValueStored.current.shortDescriptionRef}
                                    ></InputSimpleBox>
                                    {error.shortDescription && <div className="errorBar">{error.shortDescription}</div>}
                                </div>
                                <div className="halfTextBox">
                                    <div className={error.category ? "error" : ""}>
                                        <InputDropDownBox
                                            labelText="category* "
                                            getRef={categoryRef}
                                            optionsList={[
                                                { optionLabel: "Office & Workspace", optionValue: "workspace" },
                                                { optionLabel: "Leadership", optionValue: "leadership" },
                                                { optionLabel: "Design", optionValue: "design" },
                                                { optionLabel: "Technology", optionValue: "technology" },
                                                { optionLabel: "Branding", optionValue: "branding" },
                                            ]}
                                            priValue={categoryRef.current === "workspace" ? "Office & Workspace" : categoryRef.current}
                                            privCode={categoryRef.current}
                                        ></InputDropDownBox>
                                        {error.category && (
                                            <div className="errorBar">{error.category}</div>
                                        )}
                                    </div>
                                    <div className={error.placementSection ? "error" : ""}>
                                        <InputDropDownBox
                                            labelText="Placement* "
                                            getRef={placementRef}
                                            optionsList={[
                                                { optionLabel: "Normal", optionValue: "normal" },
                                                { optionLabel: "Editor Pick Section", optionValue: "editorPick" },
                                                { optionLabel: "Banner Of Page", optionValue: "bannerSelection" },
                                                { optionLabel: "In Stream", optionValue: "inStream" },
                                            ]}
                                            priValue={placementDisplayValueRef.current}
                                            privCode={placementRef.current}
                                        ></InputDropDownBox>
                                        {error.placementSection && <div className="errorBar">{error.placementSection}</div>}
                                    </div>
                                </div>
                                <div className="fullTextBox"></div>
                                <div className="halfTextBox">
                                    <div className={error.tag ? "error" : ""}>
                                        <InputSimpleBox
                                            labelText="Blog tags* <span style='font-size: x-small;'>(Two Tags Allowed)</span>"
                                            placeholder="Enter Tags separated Via Comma"
                                            getRef={tagRef}
                                            priValue={firstValueStored.current.tagRef}
                                        ></InputSimpleBox>
                                        {error.tag && <div className="errorBar">{error.tag}</div>}
                                    </div>
                                    <div className={error.date ? "error" : ""}>
                                        <InputSimpleBoxDate
                                            labelText="date*"
                                            placeholder="Enter Date"
                                            getRef={dateRef}
                                            priValue={firstValueStored.current.dateRef}
                                            disabled={true}
                                        ></InputSimpleBoxDate>
                                        {error.date && <div className="errorBar">{error.date}</div>}
                                    </div>
                                </div>
                                <div className="headingTitle">blog description / body*</div>
                                <div className="fullTextBox">
                                    <CkEditorBox
                                        labelText="blog description (subtitle)"
                                        placeholder={descriptionRef.current}
                                        getRef={descriptionRef}
                                    ></CkEditorBox>
                                </div>
                                <div className="headingTitle">SEO blog section</div>
                                <div className="halfTextBox">
                                    <div className={error.metaTitle ? "error" : ""}>
                                        <InputSimpleBox
                                            labelText="meta title*"
                                            placeholder="Enter this page's Meta title"
                                            getRef={metaTitleRef}
                                            priValue={firstValueStored.current.metaTitleRef}
                                        ></InputSimpleBox>
                                        {error.metaTitle && <div className="errorBar">{error.metaTitle}</div>}
                                    </div>
                                    <div className={error.metaDescription ? "error" : ""}>
                                        <InputSimpleBox
                                            labelText="meta description*"
                                            placeholder="Enter this page's Meta description"
                                            getRef={metaDescriptionRef}
                                            priValue={firstValueStored.current.metaDescriptionRef}
                                        ></InputSimpleBox>
                                        {error.metaDescription && <div className="errorBar">{error.metaDescription}</div>}
                                    </div>


                                </div>
                                <div className="submitButton">
                                    <div className="submitButtonBox" onClick={() => confirmBeforeSavingData()}>
                                        <span>save all changes</span>
                                        <img src={changesIcon} alt="changesIcon" />
                                    </div>
                                </div>
                            </>
                            :
                            null
                    }
                </div>
            </div>
            {confirmationScreen &&
                <div className="notificationForm">
                    <div className="mainBoxNotificationBox">
                        <div className="iconOfNotificationBox"><img src={successIcon} alt="successIcon" /></div>
                        <div className="textBoxOfNotificationBox">
                            <div className="headingOfNotification">
                                Really want to save the changes!
                            </div>
                            <div className="subTextOfNotification">
                                After updating data, it can take few minutes to update the server.
                            </div>
                            <div className="actionButtons">
                                <div className="cancelButton" onClick={() => cancelBeforeSavingData()}>Cancel</div>
                                <div className="confirmButton" onClick={() => savingData()}>Yes, Save it!</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {confirmedScreen &&
                <div className="notificationForm">
                    <div className="mainBoxNotificationBox confirmationBox">
                        <div className="iconOfNotificationBox"><img src={successIcon} alt="successIcon" /></div>
                        <div className="textBoxOfNotificationBox">
                            <div className="headingOfNotification">
                                Updated successfully
                            </div>
                            <div className="subTextOfNotification">
                                You data is successfully updated. Please wait for few minutes to reflect the changes.
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}