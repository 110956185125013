import React, { useEffect, useRef, useState } from "react";

import viewIcon from '../../assets/Common/viewIcon.svg'
import editIcon from '../../assets/Common/editIcon.svg'
import deleteIcon from '../../assets/Common/deleteIcon.svg'
import searchIcon from '../../assets/Common/searchIcon.svg'
import errorIcon from '../../assets/Common/errorSymbol.svg'
import successIcon from "../../assets/Common/successIcon.svg"

import '../../styles/Property/showPropertyTable.scss'
import { deleteProperty, getAllProperties, refreshAllProperties } from "../../services/PropertyServices";
import { useNavigate } from "react-router-dom";

export default function ShowPropertyTable() {


    const [confirmationScreen, setConfirmationScreen] = useState(false)
    const [confirmedScreen, setConfirmedScreen] = useState(false)
    const deletingPropId = useRef(0)

    const propertyListCard = useRef([])
    const [searchText, setSearchText] = useState("")
    const [displayPropertyListCard, setDisplayPropertyListCard] = useState([])

    const navigate = useNavigate();

    useEffect(() => {

        propertyListCard.current = getAllProperties()
        setDisplayPropertyListCard(getAllProperties())

        return () => {
            setSearchText("")
        }

    }, [])

    useEffect(() => {
        setDisplayPropertyListCard(propertyListCard.current.filter(entry => {
            return entry.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                entry.floor.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                entry.building.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                entry.location.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                entry.city.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                entry.state.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
        }))
    }, [searchText])

    // confirmation Box Of Delete
    const confirmationBoxOfDelete = (propId) => {
        deletingPropId.current = propId
        setConfirmationScreen(true)
    }

    // confirmation Box Of Delete close
    const cancelBoxOfDelete = () => {
        setConfirmationScreen(false)
    }

    // call Delete API now
    const deletePropertyById = () => {

        deleteProperty(deletingPropId.current).then(res => {
            setConfirmationScreen(false)
            setConfirmedScreen(true)
            refreshAllProperties()
            setTimeout(() => {
                propertyListCard.current = getAllProperties()
                setDisplayPropertyListCard(getAllProperties())
                setDisplayPropertyListCard(propertyListCard.current.filter(entry => {
                    return entry.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                        entry.floor.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                        entry.building.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                        entry.location.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                        entry.city.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                        entry.state.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
                }))
                setConfirmedScreen(false)
            }, 3000);
        }).catch(err => { console.log(err) })
    }



    return (
        <>
            <div className="showPropertyTable">
                <div className="upperBox">
                    <div className="upperHeading">all listed properties</div>
                    <div className="searchBox">
                        <input type="search" placeholder="Search" name="searchProperty" onChange={(val) => setSearchText(val.target.value)} />
                        <img src={searchIcon} alt="searchIcon" />
                    </div>
                </div>
                <div className="dataTableStyles">
                    <div className="headingOfTable">
                        <div className="headingItem">Image</div>
                        <div className="headingItem">Property Name</div>
                        <div className="headingItem">Location</div>
                        <div className="headingItem">Modification Option</div>
                    </div>
                    {displayPropertyListCard.map((entry, index) => {
                        return <div className="entryOfTable" key={entry.id}>
                            <div className="imageEntry">
                                <span>{index + 1}.</span>
                                <img src={"https://data.thecorporatedge.com/cordocs/" + entry?.images[0]?.url} alt="bannerImage" />
                            </div>
                            <div className="nameEntry">
                                {entry.name}
                            </div>
                            <div className="locationEntry">
                                {entry.floor + ", " + entry.building + ", " + entry.location + ", " + entry.city + ", " + entry.state}
                            </div>
                            <div className="modifyingOption">
                                <div className="allButtons">
                                    <div className="button viewButton" onClick={() => navigate('/view-property/' + entry.id)}>
                                        <span>View</span>
                                        <img src={viewIcon} alt="viewIcon" />
                                    </div>
                                    <div className="button editButton" onClick={() => navigate('/update-property/' + entry.id)}>
                                        <span>edit</span>
                                        <img src={editIcon} alt="editIcon" />
                                    </div>
                                    <div className="button deleteButton" onClick={() => confirmationBoxOfDelete(entry.id)}>
                                        <span>delete</span>
                                        <img src={deleteIcon} alt="deleteIcon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            {confirmationScreen &&
                <div className="notificationFormProperty">
                    <div className="mainBoxNotificationBox">
                        <div className="iconOfNotificationBox"><img src={errorIcon} alt="successIcon" /></div>
                        <div className="textBoxOfNotificationBox">
                            <div className="headingOfNotification">
                                Really want to delete?
                            </div>
                            <div className="subTextOfNotification">
                                You will not able to retrieve this again if you delete this Property.
                            </div>
                            <div className="actionButtons">
                                <div className="cancelButton" onClick={() => cancelBoxOfDelete()}>Cancel</div>
                                <div className="confirmButton" onClick={() => deletePropertyById()}>Yes, delete</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {confirmedScreen &&
                <div className="notificationFormProperty">
                    <div className="mainBoxNotificationBox confirmationBox">
                        <div className="iconOfNotificationBox"><img src={successIcon} alt="successIcon" /></div>
                        <div className="textBoxOfNotificationBox">
                            <div className="headingOfNotification">
                                Deleted successfully
                            </div>
                            <div className="subTextOfNotification">
                                You data is successfully Deleted. Please wait for few minutes to reflect the changes.
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}