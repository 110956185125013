import React, { useEffect, useRef, useState } from "react";

import "../../styles/forms/inputSimpleBox.scss";

export default function InputDropDownBox({
    priValue,
    labelText,
    optionsList,
    privCode,
    getRef,
    onChangeDropDown
}) {
    const [inputSection, setInputSection] = useState("");
    const [dropdownSelection, setDropdownSelection] = useState(false);

    const optionBox = useRef([])

    useEffect(() => {
        setInputSection(priValue);
        getRef.current = privCode
    }, [getRef, priValue, privCode]);

    useEffect(() => {
        if (optionsList) {
            optionBox.current = optionsList
        }
    }, [optionsList]);

    const changeValue = (getValue, getCode) => {
        setInputSection(getValue);
        getRef.current = getCode
    }

    return (
        <>
            <div className="inputSimpleBox" style={{ position: "relative" }} onMouseLeave={() => setDropdownSelection(false)}>
                <div className="labelOfInputBox">{labelText}</div>
                <div
                    className="mainDropDownBoxInputBox"
                    onClick={() => setDropdownSelection(!dropdownSelection)}
                    onBlur={() => setDropdownSelection(false)}
                >
                    {inputSection}
                    <span>
                        <img alt="downArrow" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAvklEQVR4nO3RwQqDMBCE4aGHYt92j75XD7ZPZxEUSsCmarKZ1flhbznMRwCllFJKKeXZDSfYeAfwBNCDNwPwAvDIIcb5elLEON97DZNC2DCWbJsg3dpjVoxtQbBibA+CDWNHECwYK4FojbGSiFYYq4HwxlhNhBfGBVEb44qohWmCKI1piiiFoUAcxVAh9mIoEVsx1Ih/MSEQOUwoxNI0cEiGf98QAfHrZ8L8RA4TEpFiQiOWujMglFJKKaWu3AfcdLEudXvTswAAAABJRU5ErkJggg==" />
                    </span>
                </div>
                {dropdownSelection ? (
                    <div className="dropDownBoxInputBox">
                        {optionsList.map((value, index) => {
                            return <div
                                key={index}
                                className="itemDropDownBoxInputBox"
                                onClick={() => {
                                    changeValue(value.optionLabel, value.optionValue);
                                    setDropdownSelection(false);
                                    if (onChangeDropDown) onChangeDropDown(value.optionValue);
                                }}
                            >
                                {value.optionLabel}
                            </div>
                        })}
                    </div>
                ) : null}
            </div>
        </>
    );
}
