import React from "react";
import WelcomeAdmin from "../components/Common/WelcomeAdmin";
import BlogTotalCount from "../components/Blog/BlogTotalCount";
import ShowBlogTable from "../components/Blog/ShowBlogTable";

export default function ShowBlogs() {

    return (
        <>
            <div className="showBlogs  lenis lenis-smooth">
                <WelcomeAdmin isExport='false'></WelcomeAdmin>
                <BlogTotalCount></BlogTotalCount>
                <ShowBlogTable></ShowBlogTable>
            </div>
        </>
    )
}