import React, { useEffect, useState } from "react";

import BlogListCard from '../../assets/blog/BlogListCard.png'
import BlogAddCard from '../../assets/blog/BlogAddCard.png'
import addNewLargeIcon from '../../assets/Common/addNewLargeIcon.svg'

import "../../styles/Property/propertyTotalCount.scss"
import { useNavigate } from "react-router-dom";
import { getUsersLength } from "../../services/UserServices";

export default function UsersTotalCount() {

    const navigate = useNavigate();
    
    const [blogCount, setBlogCount] = useState([])

    useEffect(() => {

        setBlogCount(getUsersLength())

        setInterval(() => {
            setBlogCount(getUsersLength())
        }, 3000);

    }, [])

    return (
        <>
            <div className="propertyTotalCount">
                <div className="listOfCards">
                    <div className="listingCard">
                        <div className="backgroundImage">
                            <img src={BlogListCard} alt="BlogListCard" />
                        </div>
                        <div className="mainContentCardList">
                            <div className="countNumber">{blogCount}</div>
                            <div className="aboutCount">Users have been listed till now</div>
                        </div>
                    </div>
                    <div className="addListCard" onClick={() => navigate('/new-users')}>
                        <div className="backgroundImage">
                            <img src={BlogAddCard} alt="BlogAddCard" />
                        </div>
                        <div className="centerCardInfo">
                            <div className="aboutCard">Add New<br />User / Admin</div>
                            <div className="leonOfCard">
                                <img src={addNewLargeIcon} alt="addNewLargeIcon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}