import React from "react";
import WelcomeAdmin from "../components/Common/WelcomeAdmin";
import PropertyTotalCount from "../components/Property/PropertyTotalCount";
import ShowPropertyTable from "../components/Property/ShowPropertyTable";

export default function ShowProperty() {

    return (
        <>
            <div className="showProperty lenis lenis-smooth">
                <WelcomeAdmin isExport='true'></WelcomeAdmin>
                <PropertyTotalCount></PropertyTotalCount>
                <ShowPropertyTable></ShowPropertyTable>
            </div>
        </>
    )
}