import React, { useEffect, useState } from "react";

import "../../styles/forms/inputSimpleBox.scss";

const InputSimpleBox = React.memo(function InputSimpleBox({typeInput, priValue, labelText, placeholder, getRef, disabled, onChangeInput}) {
    const [inputSection, setInputSection] = useState("");

    useEffect(() => {
        if (priValue) {
            setInputSection(priValue);
        }
    }, [priValue]);

    return (
        <>
            <div className="inputSimpleBox">
                <div className="labelOfInputBox" dangerouslySetInnerHTML={{__html:labelText}}></div>
                <input
                    type={typeInput ? typeInput : "text"}
                    name="labelOfInputBox"
                    placeholder={placeholder}
                    ref={getRef}
                    value={inputSection}
                    onChange={(e) => {
                        setInputSection(e.target.value);
                        if (onChangeInput) onChangeInput(e);
                    }}
                    disabled={disabled}
                />
            </div>
        </>
    );
})

export default InputSimpleBox