import React, { useEffect, useRef, useState } from "react";

import changesIcon from "../assets/Common/changesIcon.svg";
import successIcon from "../assets/Common/successIcon.svg";

import "../styles/Property/addProperty.scss";
import UploadBoxExtended from "../components/Forms/UploadBoxExtended";
import InputSimpleBox from "../components/Forms/InputSimpleBox";
import { getAllAmenities } from "../services/AmenitiesServices";
import {
  deletePropertyImage,
  getPropertiesById,
  getPropertiesIndexById,
  refreshAllProperties,
  updateProperty,
} from "../services/PropertyServices";
import UploadBoxBulk from "../components/Forms/UploadBoxBulk";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import backButton from "../assets/Common/backButton.svg";
import UploadBox from "../components/Forms/UploadBox";
import { getAllLocation } from "../services/LocationServices";
import InputDropDownBox from "../components/Forms/InputDropDownBox";

export default function EditProperty() {
  // getting param
  const { paramPropId } = useParams();

  const addPropertyPanelRef = useRef();

  // Upload Related Handling
  const uploadingImageCount = useRef(0);

  const navigate = useNavigate();

  // All Images Handling
  const [bulkImage, setBulkImage] = useState([]);
  const [bulkImageDemonstrate, setBulkImageDemonstrate] = useState([
    { id: 0, index: 0, url: "", active: false },
  ]);
  const bulkImageRef = useRef([] | null);

  const [currentImageIndex, setCurrentImageIndex] = useState(1);

  const [dataLoaded, setDataLoaded] = useState(false);

  const [allLocation, setAllLocation] = useState([]);
  const [indiaState, setIndiaState] = useState("Karnataka");

  const [pinCodeState, setPinCodeState] = useState("");
  const [pinCodeStateResult, setPinCodeStateResult] = useState("Bengaluru");
  const [pinCodeIDStateResult, setPinCodeIDStateResult] = useState("Bengaluru");

  const pushPhoto = (file, number) => {
    if (number === 0) {
      setBulkImage((priv) => {
        let imagesArray = [...priv, file];
        bulkImageRef.current = imagesArray;
        return imagesArray;
      });
    } else {
      let index = Number(number) - 1;
      bulkImageRef.current[index] = file;
    }
  };

  const [nameState, setNameState] = useState("");
  const [floorState, setFloorState] = useState("");
  const [buildingState, setBuildingState] = useState("");
  const [locationState, setLocationState] = useState("");
  const [cityState, setCityState] = useState("");
  const [stateState, setStateState] = useState("");

  const imageCountStore = useRef();

  const nameRef = useRef();
  const descriptionRef = useRef();

  const contactRef = useRef();

  const latitudeRef = useRef();
  const longitudeRef = useRef();

  const airportRef = useRef();
  const metroStationRef = useRef();
  const spotRef = useRef();
  const stationRef = useRef();

  const floorRef = useRef();
  const buildingRef = useRef();
  const locationRef = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const pincodeRef = useRef();

  const firstValueStored = useRef({
    nameRef: "",
    descriptionRef: "",
    contactRef: "",
    latitudeRef: "",
    longitudeRef: "",
    airportRef: "",
    metroStationRef: "",
    spotRef: "",
    stationRef: "",
    floorRef: "",
    buildingRef: "",
    locationRef: "",
    cityRef: "",
    stateRef: "",
    pincodeRef: "",
  });

  const [confirmationScreen, setConfirmationScreen] = useState(false);
  const [confirmedScreen, setConfirmedScreen] = useState(false);

  // For Amenities
  const amenitiesValue = useRef([]);
  const [amenitiesState, setAmenitiesState] = useState([]);

  const updateAmenitiesVar = (param) => {
    amenitiesValue.current = amenitiesValue.current.map((entry) => {
      if (entry.name === param) {
        entry.active = !entry.active;
      }
      return entry;
    });
    setAmenitiesState(amenitiesValue.current);
  };

  // For Offering
  const offeringVar = useRef({
    teamRoom: false,
    suites: false,
    directorCabin: false,

    dailyPass: false,
    dedicateDesk: false,

    boardMeetingRoom: false,
    functionRoom: false,
    virtualRoom: false,

    ce_postal: false,
    ce_registered: false,
    virtual_office: false,

    event: false,
  });

  const [offeringState, setOfferingState] = useState({
    ...offeringVar.current,
  });

  const updateOfferingVar = (param) => {
    offeringVar.current = {
      ...offeringVar.current,
      [param]: !offeringVar.current[param],
    };
    setOfferingState({ ...offeringVar.current });
  };

  // start fetching data

  useEffect(() => {
    setAllLocation(getAllLocation());

    let centralWindow = document.querySelector(".centerPanel");
    centralWindow.scrollTo(0, 0);

    // getting all Data
    let getData = getPropertiesById(paramPropId);

    // setting all Variables
    nameRef.current = getData.name;
    setNameState(getData.name);
    descriptionRef.current = getData.description;

    contactRef.current = getData.contact;

    latitudeRef.current = getData.latitude;
    longitudeRef.current = getData.longitude;

    airportRef.current = getData.airport;
    metroStationRef.current = getData.metrostation;
    spotRef.current = getData.spot;
    stationRef.current = getData.station;

    floorRef.current = getData.floor;
    setFloorState(getData.floor);
    buildingRef.current = getData.building;
    setBuildingState(getData.building);
    locationRef.current = getData.location;
    setLocationState(getData.location);
    cityRef.current = getData.city;
    setCityState(getData.city);
    setPinCodeStateResult(getData.city);
    setPinCodeIDStateResult(getData.city);
    stateRef.current = getData.state;
    setStateState(getData.state);
    setIndiaState(getData.state);
    pincodeRef.current = getData.pincode;

    firstValueStored.current = {
      nameRef: nameRef.current,
      descriptionRef: descriptionRef.current,
      contactRef: contactRef.current,
      latitudeRef: latitudeRef.current,
      longitudeRef: longitudeRef.current,
      airportRef: airportRef.current,
      metroStationRef: metroStationRef.current,
      spotRef: spotRef.current,
      stationRef: stationRef.current,
      floorRef: floorRef.current,
      buildingRef: buildingRef.current,
      locationRef: locationRef.current,
      cityRef: cityRef.current,
      stateRef: stateRef.current,
      pincodeRef: pincodeRef.current,
    };

    // setting all images
    if (getData.images.length > 0) {
      setBulkImageDemonstrate(
        getData.images.map((entry, index) => {
          return {
            id: entry.id,
            index: index,
            url: "https://data.thecorporatedge.com/cordocs/" + entry?.url,
            active: true,
          };
        })
      );
    }

    // setting all offerings

    updateOfferingVarShow(
      "directorCabin",
      getData?.privatecabin?.directorcabin
    );
    updateOfferingVarShow("suites", getData?.privatecabin?.suites);
    updateOfferingVarShow("teamRoom", getData?.privatecabin?.teamroom);

    updateOfferingVarShow("dailyPass", getData?.sharedworkspace?.dailypass);
    updateOfferingVarShow(
      "dedicateDesk",
      getData?.sharedworkspace?.dedicatedesk
    );

    updateOfferingVarShow(
      "boardMeetingRoom",
      getData?.meetingroom?.boardmeetingroom
    );
    updateOfferingVarShow("functionRoom", getData?.meetingroom?.functionroom);
    updateOfferingVarShow("virtualRoom", getData?.meetingroom?.meetingroom);

    updateOfferingVarShow("ce_postal", getData?.virtualspace?.ce_postal);
    updateOfferingVarShow(
      "ce_registered",
      getData?.virtualspace?.ce_registered
    );
    updateOfferingVarShow(
      "virtual_office",
      getData?.virtualspace?.virtual_office
    );

    updateOfferingVarShow("event", getData?.events?.event);

    // updating all amenities
    let tempVar = getAllAmenities().map((entry) => {
      let returnableItem = {
        ...entry,
        active: false,
      };
      getData?.amenities.forEach((element) => {
        if (element.id === entry.id) {
          returnableItem.active = true;
        }
      });
      return returnableItem;
    });
    amenitiesValue.current = tempVar;
    setAmenitiesState(tempVar);

    // getting Index position of property
    setCurrentImageIndex(getPropertiesIndexById(paramPropId));

    setDataLoaded(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateOfferingVarShow = (param, states) => {
    offeringVar.current = { ...offeringVar.current, [param]: states };
    setOfferingState({ ...offeringVar.current });
  };

  // update Deleting Photo
  const handleDeletePhotoClick = (getIndex) => {
    console.log("deleting Request: " + getIndex);
    setBulkImageDemonstrate((prev) => {
      return prev.map((item, index) => {
        if (index === getIndex) {
          return { ...item, active: false };
        } else {
          return item;
        }
      });
    });
  };

  // Confirm before saving data
  const confirmBeforeSavingData = () => {
    if (validateInputs()) {
      setConfirmationScreen(true);
    } else addPropertyPanelRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // Confirm before saving data
  const cancelBeforeSavingData = () => {
    setConfirmationScreen(false);
  };

  // Validate the input
  const [error, setError] = React.useState({
    name: "",
    description: "",
    contact: "",
    latitude: "",
    longitude: "",
    airport: "",
    metroStation: "",
    spot: "",
    station: "",
    floor: "",
    building: "",
    location: "",
    city: "",
    state: "",
    pinCode: "",
    image: "",
  });

  const validateInputs = () => {
    let errors = {};

    if (!nameRef.current.value) errors.name = "Property name is required";
    if (!descriptionRef.current.value)
      errors.description = "Property Description is required";
    if (!latitudeRef.current.value)
      errors.latitude = "Latitude is required to show map";
    if (!longitudeRef.current.value)
      errors.longitude = "Longitude is required to show map";
    if (!floorRef.current.value) errors.floor = "Address is required";
    if (!buildingRef.current.value) errors.building = "Addressis required";
    if (!locationRef.current.value) errors.location = "Address is required";
    if (!cityRef.current) errors.city = "Address is required";
    if (!stateRef.current.value) errors.state = "Address is required";
    if (!pincodeRef.current.value)
      errors.pinCode = "Pin code of this location is required";

    if (imageCountStore.current + bulkImage.length < 4)
      errors.image = "Min 4 image is required";

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    imageCountStore.current = bulkImageDemonstrate.filter(
      (entry) => entry.active === true
    ).length;
  }, [bulkImageDemonstrate]);

  // Start uploading data
  const uploadDataNow = () => {
    setConfirmationScreen(false);
    setConfirmedScreen(true);

    deleteImageNow();

    let sendableData = {
      name: nameRef.current?.value,
      floor: floorRef.current?.value,
      building: buildingRef.current?.value,
      location: locationRef.current?.value,
      pincode: pincodeRef.current?.value,
      city: cityRef.current,
      state: stateRef.current?.value,
      country: "INDIA",
      description: descriptionRef.current?.value,
      airport: airportRef.current?.value,
      station: stationRef.current?.value,
      metrostation: metroStationRef.current?.value,
      spot: spotRef.current?.value,
      latitude: latitudeRef.current?.value,
      longitude: longitudeRef.current?.value,
      contact: contactRef.current?.value,
      privatecabin: {
        teamroom: offeringVar.current.teamRoom,
        suites: offeringVar.current.suites,
        directorcabin: offeringVar.current.directorCabin,
      },
      sharedworkspace: {
        dedicatedesk: offeringVar.current.dedicateDesk,
        dailypass: offeringVar.current.dailyPass,
      },
      meetingroom: {
        boardmeetingroom: offeringVar.current.boardMeetingRoom,
        functionroom: offeringVar.current.functionRoom,
        virtualroom: offeringVar.current.virtualRoom,
      },
      virtualspace: {
        virtual_office: offeringVar.current.virtual_office,
        ce_postal: offeringVar.current.ce_postal,
        ce_registered: offeringVar.current.ce_registered,
      },
      events: {
        event: offeringVar.current.event,
      },
      amenities: [],
    };

    sendableData.amenities = amenitiesValue.current
      .filter((entry) => entry.active === true)
      .map((entry) => {
        return { name: entry.name };
      });

    updateProperty(paramPropId, sendableData)
      .then((res) => {
        uploadAllImages();
      })
      .catch((err) => {
        console.log(err);
        setConfirmedScreen(false);
      });
  };

  const uploadAllImages = () => {
    bulkImage.forEach((entry, index) => {
      setTimeout(() => {
        uploadImage(
          paramPropId,
          entry,
          nameRef.current?.value + "o1212Extra1212"
        );
      }, 500 * index);
    });
    setTimeout(() => {
      refreshAllProperties();
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }, 4000);
  };

  const uploadImage = (propId, file, fileName) => {
    let formData = new FormData();
    let token = "Bearer " + sessionStorage.getItem("egdeetaroprocdnauiv");

    // Adding files to the formdata
    formData.append("file", file);
    formData.append("title", fileName);

    axios({
      // Endpoint to send files
      url: "https://data.thecorporatedge.com/CORManager/property/image/" + propId,
      method: "POST",
      headers: {
        // Add any auth token here
        authorization: token,
      },

      // Attaching the form data
      data: formData,
    })
      // Handle the response from backend here
      .then((res) => {
        uploadingImageCount.current++;
      });
  };

  // Deleting All InActive Images
  const deleteImageNow = () => {
    let deletingArray = bulkImageDemonstrate.filter(
      (entry) => entry.active !== true
    );
    console.log(deletingArray);

    deletingArray.forEach((entry, index) => {
      setTimeout(() => {
        deletingImageNotInUse(entry.id);
      }, 500 * index);
    });
  };

  const deletingImageNotInUse = (deletingImageId) => {
    deletePropertyImage(deletingImageId)
      .then((res) => {
        console.log("Image Deleted");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // This function will be called whenever pincode changes

  useEffect(() => {
    // console.log(pinCodeState);

    if (pinCodeState.length > 6) {
      alert("Please enter a valid pincode");
    }
    if (pinCodeState.length === 6) {
      axios
        .get(`https://api.postalpincode.in/pincode/${pinCodeState}`)
        .then((response) => {
          const data = response.data;
          if (data[0].Status === "Success") {
            const cityName = data[0].PostOffice[0].District;
            const blockName = data[0].PostOffice[0].Block;
            if (blockName === "New Delhi") {
              allLocation.forEach((entry) => {
                if (entry.city === "New Delhi") {
                  setPinCodeIDStateResult(entry.city);
                  setIndiaState(entry.state);
                }
              });
              setPinCodeStateResult("New Delhi");
            } else if (
              cityName === "Bangalore" ||
              cityName === "Bangalore Rural"
            ) {
              allLocation.forEach((entry) => {
                if (entry.city === "Bengaluru") {
                  setPinCodeIDStateResult(entry.city);
                  setIndiaState(entry.state);
                }
              });
              setPinCodeStateResult("Bengaluru");
            } else if (cityName === "Gurgaon") {
              allLocation.forEach((entry) => {
                if (entry.city === "Gurugram") {
                  setPinCodeIDStateResult(entry.city);
                  setIndiaState(entry.state);
                }
              });
              setPinCodeStateResult("Gurugram");
            }
          } else {
            console.log("Pincode not found");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [allLocation, pinCodeState]);

  const changeStateUsingCity = (cityId) => {
    allLocation.forEach((entry) => {
      if (entry.city === cityId) {
        setIndiaState(entry.state);
      }
    });
  };

  return (
    <>
      <div className="addProperty lenis lenis-smooth">
        <div className="addPropertyPanel">
          <div className="headingTitle">
            <div className="backButton" onClick={() => navigate("/")}>
              <img src={backButton} alt="backButton" />
              <span>Back</span>
            </div>
            <span>view your uploaded property</span>
          </div>
          <div className="dataTableStyles">
            <div className="entryOfTableProperty">
              <div className="imageEntry">
                <span>{currentImageIndex + 1}.</span>
                <img src={bulkImageDemonstrate[0].url} alt="bannerImage" />
              </div>
              <div className="nameEntry">{nameState}</div>
              <div className="locationEntry">
                {floorState +
                  ", " +
                  buildingState +
                  ", " +
                  locationState +
                  ", " +
                  cityState +
                  ", " +
                  stateState}
              </div>
              <div className="modifyingOption">
                <div className="allButtons">
                  <div
                    className="button"
                    style={{ background: "#14C21B" }}
                    onClick={() => confirmBeforeSavingData()}
                  >
                    <img src={changesIcon} alt="editIcon" />
                    <span>Save</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="headingTitle">upload your property image</div>
          <div className="imageUploadSection" ref={addPropertyPanelRef}>
            {bulkImageDemonstrate
              .filter((entry) => entry.active === true)
              .map((entry, index) => (
                <UploadBoxExtended
                  key={index}
                  currentIndex={entry.index}
                  labelText={"photo " + (index + 1)}
                  staticImage={entry.url}
                  clickToClose={handleDeletePhotoClick}
                ></UploadBoxExtended>
              ))}
            {bulkImage.map((entry, index) => (
              <UploadBox
                labelText={
                  "photo " + Number(index + bulkImageDemonstrate.length + 1)
                }
                imgNumber={Number(index + 1)}
                imageRef={pushPhoto}
                preFilledImage={entry}
              ></UploadBox>
            ))}
            <UploadBoxBulk
              labelText="Add Image"
              imgNumber="0"
              imageRef={pushPhoto}
            ></UploadBoxBulk>
          </div>

          {error.image && <div className="errorBar">{error.image}</div>}

          {dataLoaded ? (
            <>
              <div className={error.name ? "fullTextBox error" : "fullTextBox"}>
                <InputSimpleBox
                  labelText="your property name* ( h1 heading )"
                  placeholder="Enter Property Name"
                  getRef={nameRef}
                  priValue={firstValueStored.current.nameRef}
                ></InputSimpleBox>
              </div>
              <div
                className={
                  error.description ? "fullTextBox error" : "fullTextBox"
                }
              >
                <InputSimpleBox
                  labelText="your property description*"
                  placeholder="Enter Property Description"
                  getRef={descriptionRef}
                  priValue={firstValueStored.current.descriptionRef}
                ></InputSimpleBox>
              </div>
              <div className="halfTextBox">
                <div className={error.floor ? "error" : ""}>
                  <InputSimpleBox
                    labelText="Floor No*"
                    placeholder="Enter Property's Floor No"
                    getRef={floorRef}
                    priValue={firstValueStored.current.floorRef}
                  ></InputSimpleBox>
                </div>
                <div className={error.building ? "error" : ""}>
                  <InputSimpleBox
                    labelText="Building Name* "
                    placeholder="Enter Property's Building Name"
                    getRef={buildingRef}
                    priValue={firstValueStored.current.buildingRef}
                  ></InputSimpleBox>
                </div>
              </div>
              <div
                className={error.location ? "fullTextBox error" : "fullTextBox"}
              >
                <InputSimpleBox
                  labelText="property Address*"
                  placeholder="Enter Property Address"
                  getRef={locationRef}
                  priValue={firstValueStored.current.locationRef}
                ></InputSimpleBox>
              </div>
              <div className="halfTextBox">
                <div className={error.pinCode ? "error" : ""}>
                  <InputSimpleBox
                    labelText="Pincode*"
                    placeholder="Enter Pincode"
                    getRef={pincodeRef}
                    priValue={firstValueStored.current.pincodeRef}
                    onChangeInput={(e) => setPinCodeState(e.target.value)}
                  ></InputSimpleBox>
                </div>
                <div className={error.city ? "error" : ""}>
                  <InputDropDownBox
                    labelText="City* "
                    getRef={cityRef}
                    optionsList={allLocation
                      .filter((entry) => entry.city !== "ALL")
                      .map((entry) => {
                        return {
                          optionLabel: entry.city,
                          optionValue: entry.city,
                        };
                      })}
                    onChangeDropDown={(value) => changeStateUsingCity(value)}
                    priValue={pinCodeStateResult}
                    privCode={pinCodeIDStateResult}
                  ></InputDropDownBox>
                </div>
              </div>
              <div className="fullTextBox"></div>
              <div className="halfTextBox">
                <div className={error.state ? "error" : ""}>
                  <InputSimpleBox
                    labelText="State*"
                    placeholder="Enter State"
                    getRef={stateRef}
                    priValue={indiaState}
                  ></InputSimpleBox>
                </div>
                <div className={error.contact ? "error" : ""}>
                  <InputSimpleBox
                    labelText="centre contact no. "
                    placeholder="Enter Contact Number"
                    getRef={contactRef}
                    priValue={firstValueStored.current.contactRef}
                  ></InputSimpleBox>
                </div>
              </div>

              <div className="headingTitle">offerings* </div>
              <div className="offeringSection">
                <div className="innerOfferingSection">
                  <div className="offeringSectionsBox">
                    <div className="offeringSectionHeading">
                      Private work space
                    </div>
                    <div className="offeringSectionAllItemsBox">
                      <div
                        className={
                          offeringState.directorCabin
                            ? "offeringSectionItem activeOfferingSectionItem"
                            : "offeringSectionItem"
                        }
                        onClick={() => updateOfferingVar("directorCabin")}
                      >
                        <div className="selectedOrNotBox"></div>
                        <div className="labelForSelectedOrNotBox">
                          Director Cabin
                        </div>
                      </div>
                      <div
                        className={
                          offeringState.suites
                            ? "offeringSectionItem activeOfferingSectionItem"
                            : "offeringSectionItem"
                        }
                        onClick={() => updateOfferingVar("suites")}
                      >
                        <div className="selectedOrNotBox"></div>
                        <div className="labelForSelectedOrNotBox">Suites</div>
                      </div>
                      <div
                        className={
                          offeringState.teamRoom
                            ? "offeringSectionItem activeOfferingSectionItem"
                            : "offeringSectionItem"
                        }
                        onClick={() => updateOfferingVar("teamRoom")}
                      >
                        <div className="selectedOrNotBox"></div>
                        <div className="labelForSelectedOrNotBox">
                          Team Room
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="offeringSectionsBox">
                    <div className="offeringSectionHeading">
                      Shared workspace
                    </div>
                    <div className="offeringSectionAllItemsBox">
                      <div
                        className={
                          offeringState.dedicateDesk
                            ? "offeringSectionItem activeOfferingSectionItem"
                            : "offeringSectionItem"
                        }
                        onClick={() => updateOfferingVar("dedicateDesk")}
                      >
                        <div className="selectedOrNotBox"></div>
                        <div className="labelForSelectedOrNotBox">
                          Dedicated Desk
                        </div>
                      </div>
                      <div
                        className={
                          offeringState.dailyPass
                            ? "offeringSectionItem activeOfferingSectionItem"
                            : "offeringSectionItem"
                        }
                        onClick={() => updateOfferingVar("dailyPass")}
                      >
                        <div className="selectedOrNotBox"></div>
                        <div className="labelForSelectedOrNotBox">
                          Daily Pass
                        </div>
                      </div>
                    </div>
                    <div className="separator"></div>
                  </div>
                  <div className="offeringSectionsBox">
                    <div className="offeringSectionHeading">Meeting Rooms</div>
                    <div className="offeringSectionAllItemsBox">
                      <div
                        className={
                          offeringState.boardMeetingRoom
                            ? "offeringSectionItem activeOfferingSectionItem"
                            : "offeringSectionItem"
                        }
                        onClick={() => updateOfferingVar("boardMeetingRoom")}
                      >
                        <div className="selectedOrNotBox"></div>
                        <div className="labelForSelectedOrNotBox">
                          Board Meeting Room
                        </div>
                      </div>
                      <div
                        className={
                          offeringState.functionRoom
                            ? "offeringSectionItem activeOfferingSectionItem"
                            : "offeringSectionItem"
                        }
                        onClick={() => updateOfferingVar("functionRoom")}
                      >
                        <div className="selectedOrNotBox"></div>
                        <div className="labelForSelectedOrNotBox">
                          Function Room
                        </div>
                      </div>
                      <div
                        className={
                          offeringState.virtualRoom
                            ? "offeringSectionItem activeOfferingSectionItem"
                            : "offeringSectionItem"
                        }
                        onClick={() => updateOfferingVar("virtualRoom")}
                      >
                        <div className="selectedOrNotBox"></div>
                        <div className="labelForSelectedOrNotBox">
                          Virtual Room
                        </div>
                      </div>
                    </div>
                    <div className="separator"></div>
                  </div>
                  <div className="offeringSectionsBox">
                    <div className="offeringSectionHeading">
                      Virtual Offices
                    </div>
                    <div className="offeringSectionAllItemsBox">
                      <div
                        className={
                          offeringState.ce_postal
                            ? "offeringSectionItem activeOfferingSectionItem"
                            : "offeringSectionItem"
                        }
                        onClick={() => updateOfferingVar("ce_postal")}
                      >
                        <div className="selectedOrNotBox"></div>
                        <div className="labelForSelectedOrNotBox">
                          CE_Postal
                        </div>
                      </div>
                      <div
                        className={
                          offeringState.ce_registered
                            ? "offeringSectionItem activeOfferingSectionItem"
                            : "offeringSectionItem"
                        }
                        onClick={() => updateOfferingVar("ce_registered")}
                      >
                        <div className="selectedOrNotBox"></div>
                        <div className="labelForSelectedOrNotBox">
                          CE_Registered
                        </div>
                      </div>
                      <div
                        className={
                          offeringState.virtual_office
                            ? "offeringSectionItem activeOfferingSectionItem"
                            : "offeringSectionItem"
                        }
                        onClick={() => updateOfferingVar("virtual_office")}
                      >
                        <div className="selectedOrNotBox"></div>
                        <div className="labelForSelectedOrNotBox">
                          Virtual Office
                        </div>
                      </div>
                    </div>
                    <div className="separator"></div>
                  </div>
                  <div className="offeringSectionsBox">
                    <div className="offeringSectionHeading">Event</div>
                    <div className="offeringSectionAllItemsBox">
                      <div
                        className={
                          offeringState.event
                            ? "offeringSectionItem activeOfferingSectionItem"
                            : "offeringSectionItem"
                        }
                        onClick={() => updateOfferingVar("event")}
                      >
                        <div className="selectedOrNotBox"></div>
                        <div className="labelForSelectedOrNotBox">Event</div>
                      </div>
                    </div>
                    <div className="separator"></div>
                  </div>
                </div>
              </div>

              <div className="headingTitle">Amenities* </div>
              <div className="amenitiesSection">
                <div className="offeringSectionHeading">
                  Select your Available amenities
                </div>
                <div className="innerAmenitiesSection">
                  {amenitiesState.map((entry) => {
                    return (
                      <div
                        className={
                          entry.active
                            ? "amenitiesSectionItem activeAmenitiesSectionItem"
                            : "amenitiesSectionItem"
                        }
                        onClick={() => updateAmenitiesVar(entry.name)}
                      >
                        <div className="selectedOrNotBox"></div>
                        <div className="imgForSelectedOrNotBox">
                          <img
                            src={
                              "https://data.thecorporatedge.com/cordocs/" +
                              entry.fileData.url
                            }
                            alt=""
                          />
                        </div>
                        <div className="labelForSelectedOrNotBox">
                          {entry.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="headingTitle">Location </div>
              <div className="halfTextBox">
                <div className={error.longitude ? "error" : ""}>
                  <InputSimpleBox
                    labelText="enter your location longitude*"
                    placeholder="e.g. 41.233244"
                    getRef={longitudeRef}
                    priValue={firstValueStored.current.longitudeRef}
                  ></InputSimpleBox>
                </div>
                <div className={error.latitude ? "error" : ""}>
                  <InputSimpleBox
                    labelText="enter your location LATITUDE*"
                    placeholder="e.g. 73.343323"
                    getRef={latitudeRef}
                    priValue={firstValueStored.current.latitudeRef}
                  ></InputSimpleBox>
                </div>
              </div>

              <div className="headingTitle">What's nearby? </div>
              <div className="halfTextBox">
                <div className={error.airport ? "error" : ""}>
                  <InputSimpleBox
                    labelText="airport"
                    placeholder="Near By Airport"
                    getRef={airportRef}
                    priValue={firstValueStored.current.airportRef}
                  ></InputSimpleBox>
                </div>
                <div className={error.metroStation ? "error" : ""}>
                  <InputSimpleBox
                    labelText="metro station "
                    placeholder="Near By Metro Station"
                    getRef={metroStationRef}
                    priValue={firstValueStored.current.metroStationRef}
                  ></InputSimpleBox>
                </div>
              </div>
              <div className="fullTextBox"></div>
              <div className="halfTextBox">
                <div className={error.spot ? "error" : ""}>
                  <InputSimpleBox
                    labelText="famous spot"
                    placeholder="Near By Famous Spot"
                    getRef={spotRef}
                    priValue={firstValueStored.current.spotRef}
                  ></InputSimpleBox>
                </div>
                <div className={error.station ? "error" : ""}>
                  <InputSimpleBox
                    labelText="hotel"
                    placeholder="Near By Hotel"
                    getRef={stationRef}
                    priValue={firstValueStored.current.stationRef}
                  ></InputSimpleBox>
                </div>
              </div>
              <div className="submitButton">
                <div
                  className="submitButtonBox"
                  onClick={() => confirmBeforeSavingData()}
                  // onClick={() => deleteImageNow()}
                >
                  <span>save all changes</span>
                  <img src={changesIcon} alt="changesIcon" />
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      {confirmationScreen && (
        <div className="notificationForm">
          <div className="mainBoxNotificationBox">
            <div className="iconOfNotificationBox">
              <img src={successIcon} alt="successIcon" />
            </div>
            <div className="textBoxOfNotificationBox">
              <div className="headingOfNotification">
                Really want to save the changes!
              </div>
              <div className="subTextOfNotification">
                After updating data, it can take few minutes to update the
                server.
              </div>
              <div className="actionButtons">
                <div
                  className="cancelButton"
                  onClick={() => cancelBeforeSavingData()}
                >
                  Cancel
                </div>
                <div className="confirmButton" onClick={() => uploadDataNow()}>
                  Yes, Save it!
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {confirmedScreen && (
        <div className="notificationForm">
          <div className="mainBoxNotificationBox confirmationBox">
            <div className="iconOfNotificationBox">
              <img src={successIcon} alt="successIcon" />
            </div>
            <div className="textBoxOfNotificationBox">
              <div className="headingOfNotification">Updated successfully</div>
              <div className="subTextOfNotification">
                You data is successfully updated. Please wait for few minutes to
                reflect the changes.
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
