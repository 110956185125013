import React from "react";
import WelcomeAdmin from "../components/Common/WelcomeAdmin";
import UsersTotalCount from "../components/Users/UsersTotalCount";
import ShowUsersTable from "../components/Users/ShowUsersTable";

export default function ShowUsers() {

    return (
        <>
            <div className="showBlogs  lenis lenis-smooth">
                <WelcomeAdmin isExport='false'></WelcomeAdmin>
                <UsersTotalCount></UsersTotalCount>
                <ShowUsersTable></ShowUsersTable>
            </div>
        </>
    )
}