import React, { useRef } from "react";

import uploadAddButton from '../../assets/Common/uploadAddButton.svg'

import '../../styles/forms/uploadBox.scss'

export default function UploadBoxBulk({ labelText, imgNumber, imageRef }) {

    const inputRef = useRef(null);

    const handleClick = () => {
        // 👇️ open file input box on click of another element
        inputRef.current.click();
    };

    const showImagePreview = (e) => {
        let selectedFile = e.target.files.item(0)
        if (selectedFile) {
            const validExtensions = ['image/png', 'image/jpeg', 'image/jpg'];
            const maxSizeInBytes = 1 * 1024 * 1024; // 1MB
            if (!validExtensions.includes(selectedFile.type) || selectedFile.size > maxSizeInBytes) {
                alert('Invalid file. Please select a .png, .jpg, or .jpeg file that is less than 1MB.');
                selectedFile = null;
            }
            else imageRef(selectedFile, Number(imgNumber))
        } else {
        }
    }

    return (
        <>
            <div className="uploadItemBox">
                <div className="labelItem">{labelText}</div>
                <div className="uploadBox" onClick={() => handleClick()}>
                    max file size is<br />1 MB
                    <img src={uploadAddButton} alt="uploadAddButton" />
                </div>
            </div>
            <input className="displayNone" ref={inputRef} type='file' accept=".jpg,.png,.jpeg" onChange={showImagePreview} />
        </>
    )
}