import React, { useEffect, useRef, useState } from "react";

// import viewIcon from '../../assets/Common/viewIcon.svg'
// import editIcon from '../../assets/Common/editIcon.svg'
import deleteIcon from '../../assets/Common/deleteIcon.svg'
import searchIcon from '../../assets/Common/searchIcon.svg'
import errorIcon from '../../assets/Common/errorSymbol.svg'
import successIcon from "../../assets/Common/successIcon.svg"

import '../../styles/users/showUsersTable.scss'
// import { useNavigate } from "react-router-dom";
import { deleteUsers, getAllUsers, refreshAllUsers } from "../../services/UserServices";

export default function ShowUsersTable() {


    const [confirmationScreen, setConfirmationScreen] = useState(false)
    const [confirmedScreen, setConfirmedScreen] = useState(false)
    const deletingPropId = useRef(0)

    // const navigate = useNavigate();

    const blogListCard = useRef([])
    const [searchText, setSearchText] = useState("")
    const [displayBlogListCard, setDisplayBlogListCard] = useState([])

    useEffect(() => {

        blogListCard.current = getAllUsers()
        setDisplayBlogListCard(getAllUsers())

        return () => {
            setSearchText("")
        }

    }, [])

    useEffect(() => {
        if (blogListCard.current.length > 0) {
            setDisplayBlogListCard(blogListCard.current.filter(entry => {
                return entry.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                    entry.username.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                    entry.email.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
            }))
        }
    }, [searchText])


    // confirmation Box Of Delete
    const confirmationBoxOfDelete = (propId) => {
        deletingPropId.current = propId
        setConfirmationScreen(true)
    }

    // confirmation Box Of Delete close
    const cancelBoxOfDelete = () => {
        setConfirmationScreen(false)
    }

    // call Delete API now
    const deletePropertyById = () => {

        deleteUsers(deletingPropId.current).then(res => {
            setConfirmationScreen(false)
            setConfirmedScreen(true)
            refreshAllUsers()
            setTimeout(() => {
                blogListCard.current = getAllUsers()
                setDisplayBlogListCard(getAllUsers())
                if (blogListCard.current.length > 0) {
                    setDisplayBlogListCard(blogListCard.current.filter(entry => {
                        return entry.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                        entry.username.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                        entry.email.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
                    }))
                }
                setConfirmedScreen(false)
            }, 3000);
        }).catch(err => { console.log(err) })
    }

    return (
        <>
            <div className="showUsersTable">
                <div className="upperBox">
                    <div className="upperHeading">all uploaded users</div>
                    <div className="searchBox">
                        <input type="search" placeholder="Search" name="searchProperty" onChange={(val) => setSearchText(val.target.value)} />
                        <img src={searchIcon} alt="searchIcon" />
                    </div>
                </div>
                <div className="dataTableStyles">
                    <div className="headingOfTable">
                        <div className="headingItem">Name</div>
                        <div className="headingItem">Username</div>
                        <div className="headingItem">Email</div>
                        <div className="headingItem">Modification Option</div>
                    </div>
                    {displayBlogListCard.map((entry) => {
                        return <div className="entryOfTable" key={entry.id}>
                            <div className="nameEntry">
                                {entry.name}
                            </div>
                            <div className="nameEntry">
                                {entry.username}
                            </div>
                            <div className="nameEntry">
                                {entry.email}
                            </div>
                            <div className="modifyingOption">
                                <div className="allButtons">
                                    {/* <div className="button viewButton" onClick={() => navigate('/view-blog/' + entry.id)}>
                                        <span>View</span>
                                        <img src={viewIcon} alt="viewIcon" />
                                    </div>
                                    <div className="button editButton" onClick={() => navigate('/update-blog/' + entry.id)}>
                                        <span>edit</span>
                                        <img src={editIcon} alt="editIcon" />
                                    </div> */}
                                    <div className="button deleteButton" onClick={() => confirmationBoxOfDelete(entry.id)}>
                                        <span>delete</span>
                                        <img src={deleteIcon} alt="deleteIcon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            {confirmationScreen &&
                <div className="notificationFormProperty">
                    <div className="mainBoxNotificationBox">
                        <div className="iconOfNotificationBox"><img src={errorIcon} alt="successIcon" /></div>
                        <div className="textBoxOfNotificationBox">
                            <div className="headingOfNotification">
                                Really want to delete?
                            </div>
                            <div className="subTextOfNotification">
                                You will not able to retrieve this again if you delete this Blog.
                            </div>
                            <div className="actionButtons">
                                <div className="cancelButton" onClick={() => cancelBoxOfDelete()}>Cancel</div>
                                <div className="confirmButton" onClick={() => deletePropertyById()}>Yes, delete</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {confirmedScreen &&
                <div className="notificationFormProperty">
                    <div className="mainBoxNotificationBox confirmationBox">
                        <div className="iconOfNotificationBox"><img src={successIcon} alt="successIcon" /></div>
                        <div className="textBoxOfNotificationBox">
                            <div className="headingOfNotification">
                                Deleted successfully
                            </div>
                            <div className="subTextOfNotification">
                                You data is successfully Deleted. Please wait for few minutes to reflect the changes.
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}