import axios from "axios";

var allUsers = [];


export function refreshAllUsers() {

    axios({

        // Endpoint to send files
        url: "https://data.thecorporatedge.com/CORManager/user/",
        method: "GET",

    })

        // Handle the response from backend here
        .then((res) => {
            allUsers = res.data
            return true
        })

        // Catch errors if any
        .catch((err) => { console.log(err); return false });
}

export function getAllUsers() {
    return allUsers
}

export function getUsersLength() {
    return allUsers.length
}

export function getUsersById(getId) {
    let filterOutData = allUsers.filter(entry => entry.id === getId)
    if (filterOutData) {
        return filterOutData[0]
    }
    else {
        return []
    }
}

export function getUsersIndexById(getId) {
    let filterOutData = 0
    allUsers.forEach((entry, index) => {
        if (entry.id === getId) {
            filterOutData = index
        }
    })
    return filterOutData
}

export function addNewUser(sendableData) {
    let token = sessionStorage.getItem('egdeetaroprocdnauiv')

    return axios({

        // Endpoint to send files
        url: "https://data.thecorporatedge.com/CORManager/auth/signup",
        method: "POST",
        // Attaching the form data
        data: sendableData,
        // adding authentication
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function deleteUsers(UsersId) {
    let token = sessionStorage.getItem('egdeetaroprocdnauiv')

    return axios({

        // Endpoint to send files
        url: "https://data.thecorporatedge.com/CORManager/user/delete/" + UsersId,
        method: "DELETE",
        // adding authentication
        headers: { Authorization: `Bearer ${token}` }
    })
}