import React, { useRef, useState } from "react";
import "../styles/blogs/addBlogs.scss";

import InputSimpleBox from "../components/Forms/InputSimpleBox";
import CkEditorBox from "../components/Forms/CkEditorBox";

import changesIcon from "../assets/Common/changesIcon.svg";
import successIcon from "../assets/Common/successIcon.svg";
import UploadBox from "../components/Forms/UploadBox";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import InputSimpleBoxDate from "../components/Forms/InputSimpleBoxDate";
import { bannerSelectionCount, editorChoiceCount, inStreamCount, refreshAllBlogs } from "../services/BlogServices";
import InputDropDownBox from "../components/Forms/InputDropDownBox";

export default function AddBlog() {
    const [waitingScreen, setWaitingScreen] = useState(false);
    const [errorScreen, setErrorScreen] = useState(false);

    const addBlogPanelRef = useRef()

    const authorNameRef = useRef();
    const authorDesignationRef = useRef();

    const titleRef = useRef();
    const shortDescriptionRef = useRef();
    const categoryRef = useRef();


    const placementRef = useRef();

    const tagRef = useRef();
    const dateRef = useRef();

    const descriptionRef = useRef();

    const metaTitleRef = useRef();
    const metaDescriptionRef = useRef();

    const image1Ref = useRef();
    const image2Ref = useRef();

    const imageUploadCount = useRef(0);

    const navigate = useNavigate();

    const [error, setError] = React.useState({
        authorName: "",
        authorDesignation: "",
        title: "",
        shortDescription: "",
        category: "",
        tag: "",
        date: "",
        metaTitle: "",
        metaDescription: "",
        placementSection: "",
        img1: "",
        img2: ""
    });

    const pushPhoto = (file, number) => {
        if (number === "1" || number === 1) {
            image1Ref.current = file;
        }
        if (number === "2" || number === 2) {
            image2Ref.current = file;
        }
    };

    const validateInputs = () => {
        let errors = {};

        if (!authorNameRef.current.value) errors.authorName = "Author name is required";
        if (!authorDesignationRef.current.value) errors.authorDesignation = "Author designation is required";
        if (!titleRef.current.value) errors.title = "Title is required";
        if (!shortDescriptionRef.current.value) errors.shortDescription = "Short description is required";
        if (!categoryRef.current) errors.category = "Category is required";
        if (!placementRef.current) errors.placementSection = "Placement is required";
        if (!tagRef.current.value) errors.tag = "Tag is required";
        if (tagRef.current.value && tagRef.current.value.split(",").length > 2) errors.tag = "Only 2 Tags allowed";
        if (!dateRef.current.value) errors.date = "Date is required";
        if (!metaTitleRef.current.value) errors.metaTitle = "Meta title is required";
        if (!metaDescriptionRef.current.value) errors.metaDescription = "Meta description is required";
        if (!image1Ref.current) errors.img1 = "Banner Image is required";
        if (!image2Ref.current) errors.img2 = "Author Image is required";

        const placementErrors = {
            editorPick: "Only 2 Editor Pick Section's Blogs allowed",
            bannerSelection: "Only 3 Banner's blogs allowed",
            inStream: "Only 1 In Stream's blogs allowed"
        };
        const placementCounts = {
            editorPick: editorChoiceCount(),
            bannerSelection: bannerSelectionCount(),
            inStream: inStreamCount()
        };
        const placementLimits = {
            editorPick: 1,
            bannerSelection: 2,
            inStream: 0
        };
        if (placementCounts[placementRef.current] > placementLimits[placementRef.current]) {
            errors.placementSection = placementErrors[placementRef.current];
        }

        setError(errors);
        return Object.keys(errors).length === 0;
    };

    const startUploadingProcess = () => {
        if (validateInputs()) {
            setWaitingScreen(true);
            pushData();
        }
        else {
            addBlogPanelRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const pushData = async () => {
        const data = {
            title: titleRef.current.value,
            subtitle: shortDescriptionRef.current.value,
            type: tagRef.current.value.split(","),
            category: categoryRef.current,
            description: descriptionRef.current,
            ediorpick: "NO",
            bannerselection: "NO",
            instream: "NO",
            writer: authorNameRef.current.value,
            writertitle: authorDesignationRef.current.value,
            meta_title: metaTitleRef.current.value,
            meta_description: metaDescriptionRef.current.value,
            meta_tag: tagRef.current.value.split(","),
            date: new Date(),
            og_image: "",
        };

        data.ediorpick = placementRef.current === "editorPick" && editorChoiceCount() < 2 ? "YES" : "NO";
        data.bannerselection = placementRef.current === "bannerSelection" && bannerSelectionCount() < 3 ? "YES" : "NO";
        data.instream = placementRef.current === "inStream" && inStreamCount() === 0 ? "YES" : "NO";


        const token = "Bearer " + sessionStorage.getItem("egdeetaroprocdnauiv");

        try {
            await axios({
                url: "https://data.thecorporatedge.com/CORManager/blog/add",
                method: "POST",
                headers: {
                    authorization: token,
                },
                data: data,
            })
                .then((res) => uploadAllImages(res.data.id))
                .catch(() =>
                    alert(
                        "Unable to do this work now, Please try it again after some time."
                    )
                );
        } catch (error) {
            console.error(error);
        }
    };

    const uploadAllImages = (dataId) => {
        uploadImage(image1Ref.current, "banner", dataId);
        setTimeout(() => {
            uploadImage(image2Ref.current, "writer", dataId);
        }, 1000);
    };

    const uploadImage = (file, fileName, dataId) => {
        let formData = new FormData();
        let token = "Bearer " + sessionStorage.getItem("egdeetaroprocdnauiv");

        // Adding files to the formdata
        formData.append("file", file);
        formData.append("title", fileName);

        axios({
            // Endpoint to send files
            url: "https://data.thecorporatedge.com/CORManager/blog/image/" + dataId,
            method: "POST",
            headers: {
                // Add any auth token here
                authorization: token,
            },

            // Attaching the form data
            data: formData,
        })
            .then(() => {
                imageUploadCount.current++;

                if (imageUploadCount.current === 2) {
                    refreshAllBlogs();
                    setTimeout(() => {
                        navigate("/blogs");
                    }, 1000);
                }
            })
            .catch(() => {
                setWaitingScreen(false);
                setErrorScreen(true)
                setTimeout(() => {
                    setErrorScreen(false)
                }, 2000);
            });
    };

    return (
        <>
            <div className="addBlogs lenis lenis-smooth">
                <div className="addBlogPanel" ref={addBlogPanelRef}>
                    <div className="headingTitle">upload your blog image</div>
                    <div className="imageUploadBlogSection">
                        <div className="mainPhoto">
                            <div className={error.img1 ? "error" : ""}>
                                <UploadBox
                                    labelText="photo 1*"
                                    imgNumber="1"
                                    imageRef={pushPhoto}
                                ></UploadBox>
                            </div>
                            {error.img1 && (
                                <div className="errorBar" style={{textAlign: "center"}}>{error.img1}</div>
                            )}
                        </div>
                        <div className="authorDetailsBox">
                            <div className="authorImage">
                                <div className={error.img2 ? "error" : ""}>
                                    <UploadBox
                                        labelText="Author Image*"
                                        imgNumber="2"
                                        imageRef={pushPhoto}
                                    ></UploadBox>
                                </div>
                                {error.img2 && (
                                    <div className="errorBar">{error.img2}</div>
                                )}
                            </div>
                            <div
                                className={
                                    error.authorName || error.authorDesignation
                                        ? "authorDetails error"
                                        : "authorDetails"
                                }
                            >
                                <InputSimpleBox
                                    labelText="Author name*"
                                    placeholder="Enter Author Name*"
                                    getRef={authorNameRef}
                                ></InputSimpleBox>
                                <InputSimpleBox
                                    labelText="Author designation*"
                                    placeholder="Enter Author Designation"
                                    getRef={authorDesignationRef}
                                ></InputSimpleBox>
                                {error.authorName && (
                                    <div className="errorBar">{error.authorName}</div>
                                )}
                                {error.authorDesignation && (
                                    <div className="errorBar">{error.authorDesignation}</div>
                                )}
                            </div>
                        </div>
                        <div className="midLine"></div>
                        <div className="bottomLine"></div>
                    </div>
                    <div className="headingTitle">add your blog information</div>
                    <div className={error.title ? "fullTextBox error" : "fullTextBox"}>
                        <InputSimpleBox
                            labelText="title/ blog heading* (H1) "
                            placeholder="Enter Blog Heading"
                            getRef={titleRef}
                        ></InputSimpleBox>
                        {error.title && <div className="errorBar">{error.title}</div>}
                    </div>
                    <div
                        className={
                            error.shortDescription ? "fullTextBox error" : "fullTextBox"
                        }
                    >
                        <InputSimpleBox
                            labelText="short blog description* (subtitle)"
                            placeholder="Enter Short Description"
                            getRef={shortDescriptionRef}
                        ></InputSimpleBox>
                        {error.shortDescription && (
                            <div className="errorBar">{error.shortDescription}</div>
                        )}
                    </div>
                    <div className="halfTextBox">
                        <div className={error.category ? "error" : ""}>
                            <InputDropDownBox
                                labelText="category* "
                                getRef={categoryRef}
                                optionsList={[
                                    { optionLabel: "Office & Workspace", optionValue: "workspace" },
                                    { optionLabel: "Leadership", optionValue: "leadership" },
                                    { optionLabel: "Design", optionValue: "design" },
                                    { optionLabel: "Technology", optionValue: "technology" },
                                    { optionLabel: "Branding", optionValue: "branding" },
                                ]}
                                priValue="Select Category"
                                privCode=""
                            ></InputDropDownBox>
                            {error.category && (
                                <div className="errorBar">{error.category}</div>
                            )}
                        </div>
                        <div className={error.placementSection ? "error" : ""}>
                            <InputDropDownBox
                                labelText="Placement* "
                                getRef={placementRef}
                                optionsList={[
                                    { optionLabel: "Normal", optionValue: "normal" },
                                    { optionLabel: "Editor Pick Section", optionValue: "editorPick" },
                                    { optionLabel: "Banner Of Page", optionValue: "bannerSelection" },
                                    { optionLabel: "In Stream", optionValue: "inStream" },
                                ]}
                                priValue="Select Placement"
                                privCode=""
                            ></InputDropDownBox>
                            {error.placementSection && <div className="errorBar">{error.placementSection}</div>}
                        </div>
                    </div>
                    <div className="fullTextBox"></div>
                    <div className="halfTextBox">
                        <div className={error.tag ? "error" : ""}>
                            <InputSimpleBox
                                labelText="Blog tags* <span style='font-size: x-small;'>(Two Tags Allowed)</span>"
                                placeholder="Enter Tags separated Via Comma"
                                getRef={tagRef}
                            ></InputSimpleBox>
                            {error.tag && <div className="errorBar">{error.tag}</div>}
                        </div>
                        <div className={error.date ? "error" : ""}>
                            <InputSimpleBoxDate
                                labelText="date*"
                                placeholder="DD / MM / YYYY"
                                getRef={dateRef}
                                disabled='true'
                            ></InputSimpleBoxDate>
                            {error.date && <div className="errorBar">{error.date}</div>}
                        </div>
                    </div>
                    <div className="headingTitle">blog description / body*</div>
                    <div className="fullTextBox">
                        <CkEditorBox
                            labelText="blog description (subtitle)"
                            placeholder="<p>Enter Blog<br/><br/><br/><br/><br/></p>"
                            getRef={descriptionRef}
                        ></CkEditorBox>
                    </div>
                    <div className="headingTitle">SEO blog section</div>
                    <div className="halfTextBox">
                        <div className={error.metaTitle ? "error" : ""}>
                            <InputSimpleBox
                                labelText="meta title*"
                                placeholder="Enter this page's Meta title"
                                getRef={metaTitleRef}
                            ></InputSimpleBox>
                            {error.metaTitle && (
                                <div className="errorBar">{error.metaTitle}</div>
                            )}
                        </div>
                        <div className={error.metaDescription ? "error" : ""}>
                            <InputSimpleBox
                                labelText="meta description*"
                                placeholder="Enter this page's Meta description"
                                getRef={metaDescriptionRef}
                            ></InputSimpleBox>
                            {error.metaDescription && (
                                <div className="errorBar">{error.metaDescription}</div>
                            )}
                        </div>
                    </div>
                    <div className="submitButton">
                        <div
                            className="submitButtonBox"
                            onClick={() => startUploadingProcess()}
                        >
                            <span>save all changes</span>
                            <img src={changesIcon} alt="changesIcon" />
                        </div>
                    </div>
                </div>
            </div>
            {waitingScreen && (
                <div className="notificationFormProperty">
                    <div className="mainBoxNotificationBox confirmationBox">
                        <div className="iconOfNotificationBox">
                            <img src={successIcon} alt="successIcon" />
                        </div>
                        <div className="textBoxOfNotificationBox">
                            <div className="headingOfNotification">Please Wait</div>
                            <div className="subTextOfNotification">
                                We are uploading your Data
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {errorScreen && (
                <div className="notificationFormProperty">
                    <div className="mainBoxNotificationBox confirmationBox">
                        <div className="iconOfNotificationBox">
                            <img src={changesIcon} alt="successIcon" />
                        </div>
                        <div className="textBoxOfNotificationBox">
                            <div className="headingOfNotification">Try again after sometime.</div>
                            <div className="subTextOfNotification">
                                There is some error encountered. Please try after some time.
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
