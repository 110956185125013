import axios from "axios";

var allProperties = [];


export function refreshAllProperties() {

    axios({

        // Endpoint to send files
        url: "https://data.thecorporatedge.com/CORManager/property/",
        method: "GET",

    })

        // Handle the response from backend here
        .then((res) => {
            allProperties = res.data

            console.log(allProperties)
            return true
        })

        // Catch errors if any
        .catch((err) => { console.log(err); return false });
}

export function getAllProperties() {
    return allProperties
}

export function getPropertiesById(getId) {
    let filterOutData = allProperties.filter(entry => entry.id === getId)
    if (filterOutData) {
        return filterOutData[0]
    }
    else {
        return []
    }
}

export function getPropertiesIndexById(getId) {
    let filterOutData = 0
    allProperties.forEach((entry, index) => {
        if (entry.id === getId) {
            filterOutData = index
        }
    })
    return filterOutData
}

export function getPropertiesLength() {
    return allProperties.length
}

export function addNewProperty(sendableData) {
    let token = sessionStorage.getItem('egdeetaroprocdnauiv')

    return axios({

        // Endpoint to send files
        url: "https://data.thecorporatedge.com/CORManager/property/add",
        method: "POST",
        // Attaching the form data
        data: sendableData,
        // adding authentication
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function updateProperty(updateAbleId, sendableData) {
    let token = sessionStorage.getItem('egdeetaroprocdnauiv')

    return axios({

        // Endpoint to send files
        url: "https://data.thecorporatedge.com/CORManager/property/update/" + updateAbleId,
        method: "PUT",
        // Attaching the form data
        data: sendableData,
        // adding authentication
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function deleteProperty(propertyId) {
    let token = sessionStorage.getItem('egdeetaroprocdnauiv')

    return axios({

        // Endpoint to send files
        url: "https://data.thecorporatedge.com/CORManager/property/delete/" + propertyId,
        method: "DELETE",
        // adding authentication
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function deletePropertyImage(imageId) {
    let token = sessionStorage.getItem('egdeetaroprocdnauiv')

    return axios({

        // Endpoint to send files
        url: "https://data.thecorporatedge.com/CORManager/property/delete/image/" + imageId,
        method: "DELETE",
        // adding authentication
        headers: { Authorization: `Bearer ${token}` }
    })
}

