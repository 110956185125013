import React, { useEffect, useRef, useState } from "react";

import uploadAddButton from "../../assets/Common/uploadAddButton.svg";

import "../../styles/forms/uploadBox.scss";

export default function UploadBoxExtended(props) {
    const [isImageSelected, setIsImageSelected] = useState(false);
    const [privMode, setPrivMode] = useState(true);
    const [imagePreviewSrc, setImagePreviewSrc] = useState("");

    const inputRef = useRef(null);

    const handleClick = () => {
        // 👇️ open file input box on click of another element
        inputRef.current.click();
    };

    useEffect(() => {
        if (props.preFilledImage) {
            let fileReader = new FileReader();
            fileReader.readAsDataURL(props.preFilledImage);
            fileReader.addEventListener("load", (event) => {
                setImagePreviewSrc(event.target.result);
                setIsImageSelected(true);
                setPrivMode(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeThisBox = () => {
        props.clickToClose(props.currentIndex)
    } 

    const showImagePreview = (e) => {
        let selectedFile = e.target.files.item(0);
        if (selectedFile) {
            const validExtensions = ['image/png', 'image/jpeg', 'image/jpg'];
            const maxSizeInBytes = 1 * 1024 * 1024; // 1MB
            if (!validExtensions.includes(selectedFile.type) || selectedFile.size > maxSizeInBytes) {
                alert('Invalid file. Please select a .png, .jpg, or .jpeg file that is less than 1MB.');
                setIsImageSelected(false)
                selectedFile = null;
            }
            else {
                props.imageRef(selectedFile, Number(props.imgNumber));
                if (
                    ["image/jpeg", "image/png", "image/svg+xml"].includes(selectedFile.type)
                ) {
                    let fileReader = new FileReader();
                    fileReader.readAsDataURL(selectedFile);
                    fileReader.addEventListener("load", (event) => {
                        setImagePreviewSrc(event.target.result);
                        setIsImageSelected(true);
                        setPrivMode(false);
                    });
                }

            }
        } else {
            setIsImageSelected(false);
        }
    };

    return (
        <>
            <div className="uploadItemBox">
                <div className="labelItem">{props.labelText}</div>
                {privMode ? (
                    <div className="afterUploadBox">
                        <img
                            src={
                                props.staticImage
                                    ? props.staticImage
                                    : "https://data.thecorporatedge.com/cordocs/property/6532683373ed506fb17543b1/17.jpeg"
                            }
                            className="imagePreviewSrc"
                            alt="imagePreviewSrc"
                        />
                        <img
                            src={uploadAddButton}
                            className="uploadDeleteButton"
                            alt="uploadDeleteButton"
                            onClick={() => closeThisBox()}
                        />
                    </div>
                ) : isImageSelected ? (
                    <div className="afterUploadBox">
                        <img
                            src={imagePreviewSrc}
                            className="imagePreviewSrc"
                            alt="imagePreviewSrc"
                            onClick={() => handleClick()}
                        />
                        <img
                            src={uploadAddButton}
                            className="uploadAddButton"
                            alt="uploadAddButton"
                        />
                    </div>
                ) : (
                    <div className="uploadBox" onClick={() => handleClick()}>
                        max file size is
                        <br />1 MB
                        <img src={uploadAddButton} alt="uploadAddButton" />
                    </div>
                )}
            </div>
            <input
                className="displayNone"
                ref={inputRef}
                type="file"
                accept=".jpg,.png,.jpeg"
                onChange={showImagePreview}
            />
        </>
    );
}
