import axios from "axios";

var allAmenities = [];


export function refreshAllAmenities() {

    axios({

        // Endpoint to send files
        url: "https://data.thecorporatedge.com/CORManager/amenity/",
        method: "GET",

    })

        // Handle the response from backend here
        .then((res) => {
            allAmenities = res.data
            return true
        })

        // Catch errors if any
        .catch((err) => { console.log(err); return false });
}

export function getAllAmenities() {
    return allAmenities
}

export function getAmenitiesLength() {
    return allAmenities.length
}