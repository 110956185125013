import React, { useRef, useState } from "react";

import "../../styles/loginStyles/loginBox.scss";
import axios from "axios";

function ForgetBox({ setLoginForm, setSuccessNotification }) {
    const userName = useRef("");

    const pass1 = useRef("");
    const pass2 = useRef("");

    const opt1 = useRef("");
    const opt2 = useRef("");
    const opt3 = useRef("");
    const opt4 = useRef("");

    // Error for Otp box
    const [isError, setIsError] = useState(false);

    // Error for username Box
    const [isErrorUserName, setIsErrorUserName] = useState(false);

    // OTP related
    const [isOPTSend, setIsOPTSend] = useState(false);
    const [isOPTReSend, setIsOPTReSend] = useState(false);
    const [optNumberSec, setOptNumberSec] = useState(59);
    const [optNumberMin, setOptNumberMin] = useState(5);
    const generatedOTP = useRef("");

    // OTP Verified or not (If verified show them set password)
    const [isVerified, setIsVerified] = useState(false);

    // checking Username exist or not
    const userNameCheck = () => {
        axios({
            // Endpoint to send files
            url:
                "https://data.thecorporatedge.com/CORManager/user/check/" +
                userName.current?.value,
            method: "GET",
        })
            .then((res) => {
                sendOTPNow(res.data.email);
            })
            .catch(() => {
                setIsErrorUserName(true);
            });
    };

    // sendOtp and handle timer
    const sendOTPNow = (email) => {
        setIsErrorUserName(false);
        setIsOPTReSend(false)
        setIsOPTSend(true);
        let initialTimer = setInterval(() => {
            setOptNumberSec((priv) => {
                if (priv === 0) {
                    setOptNumberMin((old) => {
                        if (old === 0) {
                            clearInterval(initialTimer);
                            setIsOPTReSend(true)
                            setIsOPTSend(false);
                            return 5;
                        }
                        return old - 1;
                    });
                    return 59;
                }
                return priv - 1;
            });
        }, 1000);

        // Generate a more random 4-digit OTP
        generatedOTP.current = convertToFourDigits(Math.floor((1000 + Math.random() * 9000 * new Date().getMilliseconds()) % 4000));

        // sending mail
        let emailData = {
            recipient: email,
            subject: "Your OTP for Password Reset - Corporate Edge Admin Panel",
            msgBody:
                "Please use the verification code on the Corporate edge Admin panel website:" +
                generatedOTP.current +
                ". If you didn't request this, you can ignore this email or let us know." +
                " Thanks!"
        };

        axios({
            // Endpoint to send files
            url: "https://data.thecorporatedge.com/CORManager/email/send",
            method: "POST",
            data: emailData
        })
    };

    // Function to convert any number into a 4-digit number
    const convertToFourDigits = (num) => {
        return ("0000" + num).slice(-4);
    };

    // checking OTP is matched or not
    const checkOTP = () => {
        let getOTP = opt1.current?.value + opt2.current?.value + opt3.current?.value + opt4.current?.value
        getOTP = Number(getOTP)
        if (Number(generatedOTP.current) === getOTP) {
            setIsError(false)
            setIsVerified(true);
        }
        else {
            setIsError(true)
        }
        // console.log("Checking");
        // setIsVerified(true);
    };

    // If password match, send to the server and reset the UI
    const loginNow = () => {
        if (pass1.current?.value !== "" && pass1.current?.value === pass2.current?.value) {
            setIsError(false)
            let newPasswordData = {
                username: userName.current?.value,
                password: pass1.current?.value
            }
            axios({
                // Endpoint to send files
                url: "https://data.thecorporatedge.com/CORManager/user/forgot/",
                method: "PUT",
                data: newPasswordData
            }).then(() => {
                setSuccessNotification(true)
                setTimeout(() => {
                    setSuccessNotification(false)
                }, 2500);
                setLoginForm(true);
            }).catch(() => { alert("There is an technical issue. Please check your internet!") })
        }
        else {
            if(pass1.current?.value === "") 
            {
                setIsErrorUserName(true);
                setIsError(false)
            } else {
                setIsErrorUserName(false);
                setIsError(true)
            }
            
        }

    };

    return (
        <>
            {isVerified ? (
                <div className="loginBox">
                    <div className="headingOfBox">
                        <h1>Create New Password</h1>
                    </div>
                    <div className="inputBoxes">
                        <div className="inputItem">
                            <div className="labelOfInput">User ID*</div>
                            <input
                                type="text"
                                name="userId"
                                id="userId"
                                placeholder="yourname912345"
                                ref={userName}
                            />
                        </div>

                        <div className={isErrorUserName ? "inputItem error" : "inputItem"}>
                            <div className="labelOfInput">Create new password </div>
                            <input
                                type="text"
                                name="pass1"
                                id="pass1"
                                placeholder="yourpass912345"
                                ref={pass1}
                            />
                        </div>
                        <div className={isError ? "inputItem error" : "inputItem"}>
                            <div className="labelOfInput">Re-write new password </div>
                            <input
                                type="password"
                                name="pass2"
                                id="pass2"
                                placeholder="yourpass912345"
                                ref={pass2}
                            />
                        </div>
                    </div>
                    <div className="loginButton" onClick={() => loginNow()}>
                        Submit
                    </div>
                    <div className="contactUsText">
                        {/* Having trouble while login? <span>Contact to our expert</span> */}
                    </div>
                    {isError ? <div className="showErrorNow">Password not matched</div> : null}
                    {isErrorUserName ? <div className="showErrorNow">Provide New password</div> : null}
                </div>
            ) : (
                <div className="loginBox">
                    <div className="headingOfBox">
                        <h1>Create New Password</h1>
                    </div>
                    <div className="inputBoxes">
                        <div className={isErrorUserName ? "inputItem error" : "inputItem"}>
                            <div className="labelOfInput">User ID*</div>
                            {isOPTSend || isOPTReSend ?
                                <input
                                    type="text"
                                    name="userId"
                                    id="userId"
                                    disabled={true}
                                    placeholder="yourname912345"
                                    ref={userName}
                                />
                                :
                                <input
                                    type="text"
                                    name="userId"
                                    id="userId"
                                    placeholder="yourname912345"
                                    ref={userName}
                                />
                            }

                        </div>
                        <div className={isError ? "otpInput error" : "otpInput"}>
                            <div className="mainLabelOfInput">Enter OTP </div>
                            <div className="labelOfInput">Enter 4 Digit OTP </div>
                            {isOPTSend || isOPTReSend ?
                                <div className="groupInput">
                                    <input
                                        type="number"
                                        name="opt1"
                                        id="opt1"
                                        ref={opt1}
                                        min="0"
                                        max="9"
                                        onChange={(e) => {
                                            opt2.current.focus();
                                            if (e.target.value > 9) {
                                                e.target.value = 9;
                                            }
                                        }}
                                    />
                                    <input
                                        type="number"
                                        name="opt2"
                                        id="opt2"
                                        ref={opt2}
                                        min="0"
                                        max="9"
                                        onChange={(e) => {
                                            opt3.current.focus();
                                            if (e.target.value > 9) {
                                                e.target.value = 9;
                                            }
                                        }}
                                    />

                                    <input
                                        type="number"
                                        name="opt3"
                                        id="opt3"
                                        ref={opt3}
                                        min="0"
                                        max="9"
                                        onChange={(e) => {
                                            opt4.current.focus();
                                            if (e.target.value > 9) {
                                                e.target.value = 9;
                                            }
                                        }}
                                    />

                                    <input
                                        type="number"
                                        name="opt4"
                                        id="opt4"
                                        ref={opt4}
                                        min="0"
                                        max="9"
                                        onChange={(e) => {
                                            if (e.target.value > 9) {
                                                e.target.value = 9;
                                            }
                                        }}
                                    />
                                </div>
                                :
                                <div className="groupInput">
                                    <input
                                        type="number"
                                        name="opt1"
                                        id="opt1"
                                        disabled={true}
                                        ref={opt1}
                                        min="0"
                                        max="9"
                                        onChange={(e) => {
                                            opt2.current.focus();
                                            if (e.target.value > 9) {
                                                e.target.value = 9;
                                            }
                                        }}
                                    />
                                    <input
                                        type="number"
                                        name="opt2"
                                        id="opt2"
                                        ref={opt2}
                                        disabled={true}
                                        min="0"
                                        max="9"
                                        onChange={(e) => {
                                            opt3.current.focus();
                                            if (e.target.value > 9) {
                                                e.target.value = 9;
                                            }
                                        }}
                                    />

                                    <input
                                        type="number"
                                        name="opt3"
                                        id="opt3"
                                        ref={opt3}
                                        min="0"
                                        disabled={true}
                                        max="9"
                                        onChange={(e) => {
                                            opt4.current.focus();
                                            if (e.target.value > 9) {
                                                e.target.value = 9;
                                            }
                                        }}
                                    />

                                    <input
                                        type="number"
                                        name="opt4"
                                        id="opt4"
                                        ref={opt4}
                                        disabled={true}
                                        min="0"
                                        max="9"
                                        onChange={(e) => {
                                            if (e.target.value > 9) {
                                                e.target.value = 9;
                                            }
                                        }}
                                    />
                                </div>
                            }


                            {isOPTSend ? (
                                <div className="miniLabelOfInput">
                                    Resend on your email id after {optNumberMin}:{optNumberSec}{" "}
                                    sec
                                </div>
                            ) : null}

                            {isOPTReSend ? (
                                <div className="miniLabelOfInput" style={{ cursor: 'pointer' }} onClick={() => userNameCheck()}>
                                    Reset and Resend OTP Now
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {
                        isOPTSend || isOPTReSend ?
                            <div className="loginButton" onClick={() => checkOTP()}>
                                Verify OTP
                            </div>
                            :
                            <div className="loginButton" onClick={() => userNameCheck()}>
                                Send OTP
                            </div>
                    }

                    <div className="contactUsText">
                        {/* Having trouble while login? <span>Contact to our expert</span> */}
                    </div>
                    {isError ? <div className="showErrorNow">Wrong OTP</div> : null}
                    {isErrorUserName ? <div className="showErrorNow">Invalid User ID</div> : null}
                </div>
            )}
        </>
    );
}

export default ForgetBox;
