import React, { useEffect, useRef, useState } from "react";

import uploadAddButton from '../../assets/Common/uploadAddButton.svg'

import '../../styles/forms/uploadBox.scss'

export default function UploadBox({ labelText, imgNumber, imageRef, preFilledImage }) {

    const [isImageSelected, setIsImageSelected] = useState(false)
    const [imagePreviewSrc, setImagePreviewSrc] = useState('')

    const inputRef = useRef(null);

    const handleClick = () => {
        // 👇️ open file input box on click of another element
        inputRef.current.click();
    };

    useEffect(() => {
        if (preFilledImage) {
            let fileReader = new FileReader();
            fileReader.readAsDataURL(preFilledImage);
            fileReader.addEventListener('load', (event) => {
                setImagePreviewSrc(event.target.result)
                setIsImageSelected(true)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const showImagePreview = (e) => {
        let selectedFile = e.target.files.item(0)
        // Check if the file has a valid extension and size
        if (selectedFile) {
            const validExtensions = ['image/png', 'image/jpeg', 'image/jpg'];
            const maxSizeInBytes = 1 * 1024 * 1024; // 1MB
            if (!validExtensions.includes(selectedFile.type) || selectedFile.size > maxSizeInBytes) {
                alert('Invalid file. Please select a .png, .jpg, or .jpeg file that is less than 1MB.');
                setIsImageSelected(false)
                selectedFile = null;
            }
            else {
                imageRef(selectedFile, Number(imgNumber))
                // imageRef.current = selectedFile
                if (["image/jpeg", "image/png", "image/svg+xml"].includes(selectedFile.type)) {
                    let fileReader = new FileReader();
                    fileReader.readAsDataURL(selectedFile);
                    fileReader.addEventListener('load', (event) => {
                        setImagePreviewSrc(event.target.result)
                        setIsImageSelected(true)
                    })
                }
            }
        } else {
            setIsImageSelected(false)
        }
    }

    return (
        <>
            <div className="uploadItemBox">
                <div className="labelItem">{labelText}</div>
                {isImageSelected ?
                    <div className="afterUploadBox">
                        <img src={imagePreviewSrc} className="imagePreviewSrc" alt="imagePreviewSrc" onClick={() => handleClick()} />
                        <img src={uploadAddButton} className="uploadAddButton" style={{ transform: "translate(-50%, -50%) rotate(45deg)", bottom: 'initial', top: "5%", left: "95%" }} onClick={() => setIsImageSelected(false)} alt="uploadAddButton" />
                    </div>
                    :
                    <div className="uploadBox" onClick={() => handleClick()}>
                        max file size is<br />1 MB
                        <img src={uploadAddButton} alt="uploadAddButton" />
                    </div>
                }

            </div>
            <input className="displayNone" ref={inputRef} type='file' accept=".jpg,.png,.jpeg" onChange={showImagePreview} />
        </>
    )
}