export function addDummyDataSessionStorage() {
    sessionStorage.setItem("ahkilamaK", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiS2FtYWxpa2EiLCJpYXQiOjE1MTYyMzkwMjJ9.rg_bwPyRw9x3EbnAoGmH71gAi_IiPaLBQvwjrWfAsQk")
    sessionStorage.setItem("ayngavraS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU2FydmFnbnlhIiwiaWF0IjoxNTE2MjM5MDIyfQ.3mQkPp9Rh8b51Dbfu4AE9AZTxn8EY01fTNVYuM2eUlo")
    sessionStorage.setItem("adahkuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VraGFkYSIsImlhdCI6MTUxNjIzOTAyMn0.328Dxn2os1GFO8g7nQGLi4J79WYNXb1-yA8LTZDj6gM")
    sessionStorage.setItem("uhsnahduS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VkaGFuc2h1IiwiaWF0IjoxNTE2MjM5MDIyfQ.yNOsFeLAIv_YVjUp9QjpZOwY0RZ8EDonPvGfEBnqysg")
    sessionStorage.setItem("inamhkuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VraG1hbmkiLCJpYXQiOjE1MTYyMzkwMjJ9.CODzcCrqLdz08vAgv8Y8l2CcpdEVB_440PW5FinkMLE")
    sessionStorage.setItem("teerphkuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VraHByZWV0IiwiaWF0IjoxNTE2MjM5MDIyfQ.3aYPi5lPUJfayQcrR-kE7UJ9loupLI9cRkJUe4hrgv8")
    sessionStorage.setItem("reevhkuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VraHZlZXIiLCJpYXQiOjE1MTYyMzkwMjJ9.A1kw3-DJDL6_uLYtm_YlL4wIzFTr2gOkgE-ErPtbD3U")
    sessionStorage.setItem("itirkuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3Vrcml0aSIsImlhdCI6MTUxNjIzOTAyMn0.RjjiAre4eo7_l2eQuYTA8u8FxiWTiy7TmOfP_ZBI--E")
    sessionStorage.setItem("amhskuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3Vrc2htYSIsImlhdCI6MTUxNjIzOTAyMn0.yBxJvB_UCQQlzDDEuIjyWg02iuuNWTR_2wRN6J7WZZo")
    sessionStorage.setItem("ahbaluS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VsYWJoYSIsImlhdCI6MTUxNjIzOTAyMn0.OFjAuUhba53KxAFy06gZntfCtvxvZ1X9ATzUfKUEYrY")
    sessionStorage.setItem("artnamuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VtYW50cmEiLCJpYXQiOjE1MTYyMzkwMjJ9.ZTcoSEl1pmDOZt7nbPWPdLKl_dtf880TnjvsUE5jMdc")
    sessionStorage.setItem("ahdemuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VtZWRoYSIsImlhdCI6MTUxNjIzOTAyMn0.r0deHBfvGTNyJrPmVDrwv34KeP2cEjOq5ZQWuSeTGak")
    sessionStorage.setItem("nartimSu", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VtaXRyYW4iLCJpYXQiOjE1MTYyMzkwMjJ9.6GHhsNNiCs9dXkH-G6E7rYJa3O7aKqzpUPfwU661cgg")
    sessionStorage.setItem("ihkumS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VtdWtoaSIsImlhdCI6MTUxNjIzOTAyMn0.vcrdKgOEFzYsfktbkasga3j87ealBfbLkBSksjehSYo")
    sessionStorage.setItem("adnanuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VuYW5kYSIsImlhdCI6MTUxNjIzOTAyMn0.lv5lJhi5k4D7XE9CZV5fz0tAnwyqqcGfnzoil1T6bug")
    sessionStorage.setItem("anayanuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VuYXlhbmEiLCJpYXQiOjE1MTYyMzkwMjJ9.gw6mphLc14gZtbl7vtCBQ69uu1wy1xw8K38yXlJeC9w")
    sessionStorage.setItem("najararudnuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VuZGFyYXJhamFuIiwiaWF0IjoxNTE2MjM5MDIyfQ.LpV_2jfOg2eJBdePPn1o7f0u-xWaAkUrAZpGiyne-pQ")
    sessionStorage.setItem("iteenuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VuZWV0aSIsImlhdCI6MTUxNjIzOTAyMn0.6AedeKwWqUnNnhftQvycr4eQyfzk4613BfLnMXB4MSM")
    sessionStorage.setItem("itinuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VuaXRpIiwiaWF0IjoxNTE2MjM5MDIyfQ.hj8CxZ9WgAwQkVP9h4W3KdKZhTmy4OPDZfn8IcIBsUs")
    sessionStorage.setItem("ahbarpuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VwcmFiaGEiLCJpYXQiOjE1MTYyMzkwMjJ9.8S4fqqYvR8Hao-WJG85oFiDSJuuPNSVaoubfSR10jDI")
}

export function addDummyDataLocalStorage() {
    localStorage.setItem("ahkilamaK", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiS2FtYWxpa2EiLCJpYXQiOjE1MTYyMzkwMjJ9.rg_bwPyRw9x3EbnAoGmH71gAi_IiPaLBQvwjrWfAsQk")
    localStorage.setItem("ayngavraS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU2FydmFnbnlhIiwiaWF0IjoxNTE2MjM5MDIyfQ.3mQkPp9Rh8b51Dbfu4AE9AZTxn8EY01fTNVYuM2eUlo")
    localStorage.setItem("adahkuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VraGFkYSIsImlhdCI6MTUxNjIzOTAyMn0.328Dxn2os1GFO8g7nQGLi4J79WYNXb1-yA8LTZDj6gM")
    localStorage.setItem("uhsnahduS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VkaGFuc2h1IiwiaWF0IjoxNTE2MjM5MDIyfQ.yNOsFeLAIv_YVjUp9QjpZOwY0RZ8EDonPvGfEBnqysg")
    localStorage.setItem("inamhkuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VraG1hbmkiLCJpYXQiOjE1MTYyMzkwMjJ9.CODzcCrqLdz08vAgv8Y8l2CcpdEVB_440PW5FinkMLE")
    localStorage.setItem("teerphkuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VraHByZWV0IiwiaWF0IjoxNTE2MjM5MDIyfQ.3aYPi5lPUJfayQcrR-kE7UJ9loupLI9cRkJUe4hrgv8")
    localStorage.setItem("reevhkuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VraHZlZXIiLCJpYXQiOjE1MTYyMzkwMjJ9.A1kw3-DJDL6_uLYtm_YlL4wIzFTr2gOkgE-ErPtbD3U")
    localStorage.setItem("itirkuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3Vrcml0aSIsImlhdCI6MTUxNjIzOTAyMn0.RjjiAre4eo7_l2eQuYTA8u8FxiWTiy7TmOfP_ZBI--E")
    localStorage.setItem("amhskuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3Vrc2htYSIsImlhdCI6MTUxNjIzOTAyMn0.yBxJvB_UCQQlzDDEuIjyWg02iuuNWTR_2wRN6J7WZZo")
    localStorage.setItem("ahbaluS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VsYWJoYSIsImlhdCI6MTUxNjIzOTAyMn0.OFjAuUhba53KxAFy06gZntfCtvxvZ1X9ATzUfKUEYrY")
    localStorage.setItem("artnamuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VtYW50cmEiLCJpYXQiOjE1MTYyMzkwMjJ9.ZTcoSEl1pmDOZt7nbPWPdLKl_dtf880TnjvsUE5jMdc")
    localStorage.setItem("ahdemuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VtZWRoYSIsImlhdCI6MTUxNjIzOTAyMn0.r0deHBfvGTNyJrPmVDrwv34KeP2cEjOq5ZQWuSeTGak")
    localStorage.setItem("nartimSu", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VtaXRyYW4iLCJpYXQiOjE1MTYyMzkwMjJ9.6GHhsNNiCs9dXkH-G6E7rYJa3O7aKqzpUPfwU661cgg")
    localStorage.setItem("ihkumS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VtdWtoaSIsImlhdCI6MTUxNjIzOTAyMn0.vcrdKgOEFzYsfktbkasga3j87ealBfbLkBSksjehSYo")
    localStorage.setItem("adnanuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VuYW5kYSIsImlhdCI6MTUxNjIzOTAyMn0.lv5lJhi5k4D7XE9CZV5fz0tAnwyqqcGfnzoil1T6bug")
    localStorage.setItem("anayanuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VuYXlhbmEiLCJpYXQiOjE1MTYyMzkwMjJ9.gw6mphLc14gZtbl7vtCBQ69uu1wy1xw8K38yXlJeC9w")
    localStorage.setItem("najararudnuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VuZGFyYXJhamFuIiwiaWF0IjoxNTE2MjM5MDIyfQ.LpV_2jfOg2eJBdePPn1o7f0u-xWaAkUrAZpGiyne-pQ")
    localStorage.setItem("iteenuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VuZWV0aSIsImlhdCI6MTUxNjIzOTAyMn0.6AedeKwWqUnNnhftQvycr4eQyfzk4613BfLnMXB4MSM")
    localStorage.setItem("itinuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VuaXRpIiwiaWF0IjoxNTE2MjM5MDIyfQ.hj8CxZ9WgAwQkVP9h4W3KdKZhTmy4OPDZfn8IcIBsUs")
    localStorage.setItem("ahbarpuS", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJWVUkiLCJuYW1lIjoiU3VwcmFiaGEiLCJpYXQiOjE1MTYyMzkwMjJ9.8S4fqqYvR8Hao-WJG85oFiDSJuuPNSVaoubfSR10jDI")
}