import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import propertyIcon from '../../assets/sidebar/propertyIcon.png'
import blogIcon from '../../assets/sidebar/blogIcon.png'

export default function LeftSideDower() {

    const [activeNav, setActiveNav] = useState("property")

    const [proActive, setProActive] = useState(false)
    const [blogActive, setBlogActive] = useState(false)

    const navigate = useNavigate();
    const location = useLocation();

    const changeActiveNav = (newNav) => {
        setActiveNav(newNav);
    }

    useEffect(() => {
        const currentPath = location.pathname;
        switch (currentPath) {
            case '/':
                changeActiveNav('property');
                break;
            case '/new-property':
                changeActiveNav('addProperty');
                break;
            case '/blogs':
                changeActiveNav('blogs');
                break;
            case '/new-blog':
                changeActiveNav('addBlogs');
                break;
            case '/users':
                changeActiveNav('user');
                break;
            case '/new-users':
                changeActiveNav('user');
                break;
            default:
                break;
        }

        let roles = sessionStorage.getItem("roles")
        if (roles === "ROLE_ADMIN") {
            setProActive(true)
            setBlogActive(true)
        }
        if (roles === "ROLE_PROP_ACCESS") {
            setProActive(true)
            setBlogActive(false)
        }
        if (roles === "ROLE_BLOG_ACCESS") {
            setProActive(false)
            setBlogActive(true)
        }
    }, [location]);

    useEffect(() => {
        
    }, [])


    return (
        <>
            <div className="mainMenuBox">
                {proActive?
                    <div className="eachMenuSection">
                        <div className="sectionHeading">Inventory listing </div>
                        <div className={activeNav === "property" ? "menus active" : "menus"} onClick={() => { setActiveNav("property"); navigate('/') }}>
                            <div className="menuIcon">
                                <img src={propertyIcon} alt="propertyIcon" />
                            </div>
                            <div className="menuName">All Property</div>
                        </div>
                        <div className={activeNav === "addProperty" ? "menus active" : "menus"} onClick={() => { setActiveNav("addProperty"); navigate('/new-property') }}>
                            <div className="menuIcon">
                                <img src={propertyIcon} alt="propertyIcon" />
                            </div>
                            <div className="menuName">Upload New Property</div>
                        </div>
                    </div>
                    :
                    null
                }
                {blogActive?
                    <div className="eachMenuSection">
                        <div className="sectionHeading">Blog Management </div>
                        <div className={activeNav === "blogs" ? "menus active" : "menus"} onClick={() => { setActiveNav("blogs"); navigate('/blogs') }}>
                            <div className="menuIcon">
                                <img src={blogIcon} alt="propertyIcon" />
                            </div>
                            <div className="menuName">All Blogs</div>
                        </div>
                        <div className={activeNav === "addBlogs" ? "menus active" : "menus"} onClick={() => { setActiveNav("addBlogs"); navigate('/new-blog') }}>
                            <div className="menuIcon">
                                <img src={blogIcon} alt="propertyIcon" />
                            </div>
                            <div className="menuName">Add New Blogs</div>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        </>
    )
}