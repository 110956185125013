import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";

import '../styles/systemLayoutStyles/systemLayout.scss'

import errorSymbol from '../assets/Common/errorSymbol.svg'

import Lenis from "@studio-freight/lenis";

import NavbarOfAdmin from "../components/Common/NavbarOfAdmin";
import LeftSideDower from "../components/Common/LeftSideDower";

export default function SystemLayout({ setLogin }) {

    const leftPanel = useRef(HTMLElement | null)
    const centerPanel = useRef(HTMLElement | null)

    const [deleteConfirmationBox, setDeleteConfirmationBox] = useState(false)


    useEffect(() => {

        if (leftPanel != null) {
            const lenis1 = new Lenis({
                wrapper: leftPanel.current
            })

            lenis1.on('scroll', (e) => {
                // console.log(e)
            })

            function raf1(time) {
                lenis1.raf(time)
                requestAnimationFrame(raf1)
            }

            requestAnimationFrame(raf1)

        }

        if (centerPanel != null) {
            const lenis2 = new Lenis({
                wrapper: centerPanel.current
            })

            lenis2.on('scroll', (e) => {
                // console.log(e)
            })

            function raf2(time) {
                lenis2.raf(time)
                requestAnimationFrame(raf2)
            }

            requestAnimationFrame(raf2)

        }


    }, [])

    const notificationUI = (notificationName) => {
        switch (notificationName) {
            case "deleteConfirmationBox":
                setDeleteConfirmationBox(true)
                break;
        
            default:
                break;
        }
    }

    return (
        <>
            <div className="workingBody">
                <NavbarOfAdmin setLogin={setLogin}></NavbarOfAdmin>
                <div className="mainBody">
                    <div className="leftPanel" ref={leftPanel}>
                        <LeftSideDower></LeftSideDower>
                    </div>
                    <div className="centerPanel" ref={centerPanel}>
                        <Outlet context={notificationUI} />
                    </div>
                </div>
            </div>

            {/* For Delete Notification */}
            {deleteConfirmationBox ?
                <div className="notificationBar">
                    <div className="smallWhiteBox">
                        <div className="innerSmallWhiteBox">
                            <div className="textBox">
                                <div className="logoSide">
                                    <img src={errorSymbol} alt="errorSymbol" />
                                </div>
                                <div className="allMassages">
                                    <div className="heading">Really want to delete?</div>
                                    <p>One’s you will delete your listed property information you will lose all the uploaded information</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null
            }

        </>
    )
}