import React, { useEffect, useRef, useState } from "react";

import '../../styles/loginStyles/loginBox.scss'
import axios from "axios";
import { addDummyDataLocalStorage, addDummyDataSessionStorage } from "../../helper/DummyDataFiling";
import { refreshAllProperties } from "../../services/PropertyServices";
import { refreshAllBlogs } from "../../services/BlogServices";
import { refreshAllAmenities } from "../../services/AmenitiesServices";
import { refreshAllUsers } from "../../services/UserServices";
import { refreshAllLocation } from "../../services/LocationServices";


function LoginBox({ setLogin, setLoginForm }) {

    const userName = useRef("")
    const password = useRef("")
    const keepMeLogin = useRef("")

    const [isError, setIsError] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const forgetPassword = () => {
        setLoginForm(false)
    }

    const moveToInnerPage = () => {
        setIsLoading(true)
        refreshAllProperties()
        refreshAllBlogs()
        setTimeout(() => {
            refreshAllAmenities()
            refreshAllUsers()
            refreshAllLocation()
        }, 1000);
        setTimeout(() => {
            setLogin(true)
        }, 2000);
    }

    const checkInputs = () => {
        if (!userName.current?.value) {
            setIsError("Enter username");
            return false;
        }
        if (!password.current?.value) {
            setIsError("Enter Password");
            return false;
        }
        return true;
    }

    const loginNow = () => {
        if (checkInputs()) {
            let sendableData = {
                username: userName.current?.value,
                password: password.current?.value
            }
    
            axios({
    
                // Endpoint to send files
                url: "https://data.thecorporatedge.com/CORManager/auth/signin",
                method: "POST",
                // Attaching the form data
                data: sendableData,
            })
    
                // Handle the response from backend here
                .then((res) => {
                    sessionStorage.setItem("egdeetaroprocdnauiv", res.data?.accessToken)
                    sessionStorage.setItem("email", res.data?.email)
                    sessionStorage.setItem("egdeetaroprocdnauivid", res.data?.id)
                    sessionStorage.setItem("roles", res.data?.roles[0].name)
                    sessionStorage.setItem("username", res.data?.username)
                    addDummyDataSessionStorage()
    
                    if (keepMeLogin.current?.checked) {
                        localStorage.setItem("egdeetaroprocdnauiv", res.data?.accessToken)
                        localStorage.setItem("email", res.data?.email)
                        localStorage.setItem("egdeetaroprocdnauivid", res.data?.id)
                        localStorage.setItem("roles", res.data?.roles[0].name)
                        localStorage.setItem("username", res.data?.username)
                        addDummyDataLocalStorage()
                    }
    
                    moveToInnerPage()
                })
    
                // Catch errors if any
                .catch(() => { setIsError("Invalid Credentials") });
        }
    }

    // checking any one login or not
    useEffect(() => {

        if (typeof localStorage !== 'undefined' && localStorage !== null) {
            // Iterate through localStorage keys
            if (localStorage.getItem('egdeetaroprocdnauiv') !== null) {
    
                for (let i = 0; i < localStorage.length; i++) {
                    const key = localStorage.key(i);
                    const value = localStorage.getItem(key);
    
                    // Set the key-value pair in sessionStorage
                    sessionStorage.setItem(key, value);
                }

                moveToInnerPage()
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {isLoading ?
                <div className="loadingScreen">
                    <div className="waitingText">Securing Connection...</div>
                    <div className="progress">
                        <div className="color"></div>
                    </div>
                </div>
                :
                <div className="loginBox">
                    <div className="headingOfBox">
                        <h1>Welcome Back</h1>
                        <p>Enter your User ID and password to Log in?</p>
                    </div>
                    <div className="inputBoxes">
                        <div className={isError && isError === "Enter username" ? "inputItem error" : "inputItem"}>
                            <div className="labelOfInput">User ID*</div>
                            <input type="text" name="userId" id="userId" placeholder="yourname912345" ref={userName} />
                        </div>
                        <div className={isError && isError !== "Enter username" ? "inputItem error" : "inputItem"}>
                            <div className="labelOfInput">Password*</div>
                            <input type="password" name="password" id="password" placeholder="***********" ref={password} />
                        </div>
                    </div>
                    <div className="someExtraThings">
                        <div className="leftSide">
                            <input type="checkbox" name="rememberMe" id="rememberMe" ref={keepMeLogin} />
                            <span>Keep me logged in</span>
                        </div>
                        <div className="rightSide" onClick={() => forgetPassword()}>
                            Forget password?
                        </div>
                    </div>
                    <div className="loginButton" onClick={() => loginNow()}>
                        Log In
                    </div>
                    <div className="contactUsText">
                        {/* Having trouble while login?  <span>Contact to our expert</span> */}
                    </div>
                    {isError ?
                        <div className="showErrorNow">{isError}</div>
                        : null}
                </div>
            }

        </>
    )
}


export default LoginBox;