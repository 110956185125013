import React, { useEffect, useState } from "react";

import "../../styles/forms/inputSimpleBox.scss";

export default function InputSimpleBoxDate({priValue, labelText, placeholder, getRef, disabled}) {
    const [inputSection, setInputSection] = useState("");

    useEffect(() => {
        if (priValue) {
            setInputSection(priValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getOrdinalSuffix = (date) => {
        if (date % 10 === 1 && date !== 11) {
            return date + "st";
        } else if (date % 10 === 2 && date !== 12) {
            return date + "nd";
        } else if (date % 10 === 3 && date !== 13) {
            return date + "rd";
        } else {
            return date + "th";
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = getOrdinalSuffix(date.getDate());
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        return `${day}, ${month} ${year}`;
    }

    return (
        <>
            <div className="inputSimpleBox">
                <div className="labelOfInputBox">{labelText}</div>
                {/* <input
                    type="text"
                    name="labelOfInputBox"
                    placeholder={placeholder}
                    ref={getRef}
                    value={inputSection}
                    onChange={(e) => setInputSection(e.target.value)}
                    disabled={disabled}
                /> */}
                <input
                    type="text"
                    name="dateOfInputBox"
                    className="custom-date-input"
                    ref={getRef}
                    value={inputSection || formatDate(new Date().toISOString().split('T')[0])}
                    onChange={(e) => setInputSection(e.target.value)}
                    onClick={(e) => e.target.focus()}
                    disabled={disabled}
                    style={{textTransform: "capitalize"}}
                />
            </div>
        </>
    );
}
