import Lenis from "@studio-freight/lenis";
import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/Login";
import SystemLayout from "./helper/systemLayout";
import ShowProperty from "./pages/ShowProperty";
import ShowBlogs from "./pages/ShowBlogs";
import AddProperty from "./pages/AddProperty";
import AddBlog from "./pages/AddBlog";
import ViewProperty from "./pages/ViewProperty";
import EditProperty from "./pages/EditProperty";
import ViewBlog from "./pages/ViewBlog";
import EditBlog from "./pages/EditBlog";

import "./App.scss"
import ShowUsers from "./pages/ShowUsers";
import AddUser from "./pages/AddUser";

function App() {
  const lenis = new Lenis()

  lenis.on('scroll', (e) => {
    // console.log(e)
  })

  function raf(time) {
    lenis.raf(time)
    requestAnimationFrame(raf)
  }

  requestAnimationFrame(raf)


  // For Login
  const [isLogin, setLogin] = useState(false)

  const changeUI = (changeValue) => {
    setLogin(changeValue)
  }

  return (
    <>
      {
        isLogin ?
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<SystemLayout setLogin={changeUI} />}>
                <Route index element={<ShowProperty />} />
                <Route path="new-property" element={<AddProperty />} />
                <Route path="view-property/:paramPropId" element={<ViewProperty />} />
                <Route path="update-property/:paramPropId" element={<EditProperty />} />

                <Route path="blogs" element={<ShowBlogs />} />
                <Route path="new-blog" element={<AddBlog />} />
                <Route path="view-blog/:paramPropId" element={<ViewBlog />} />
                <Route path="update-blog/:paramPropId" element={<EditBlog />} />

                <Route path="users" element={<ShowUsers />} />
                <Route path="new-users" element={<AddUser />} />

                <Route path="*" element={<ShowProperty />} />
              </Route>
            </Routes>
          </BrowserRouter>
          :
          <BrowserRouter>
            <Routes>
              <Route path="/">
                <Route index element={<LoginPage setLogin={changeUI} />} />

                <Route path="*" element={<LoginPage setLogin={changeUI} />} />
              </Route>
            </Routes>
          </BrowserRouter>
      }
    </>
  );
}

export default App;
