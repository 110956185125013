import React, { useEffect, useRef, useState } from "react";
import "../styles/blogs/addBlogs.scss"

import InputSimpleBox from "../components/Forms/InputSimpleBox";
import CkEditorBox from "../components/Forms/CkEditorBox";

// import UploadBox from "../components/Forms/UploadBox";
import { useNavigate, useParams } from "react-router-dom";
import { getBlogById, getBlogIndexById } from "../services/BlogServices";

import editIcon from '../assets/Common/editIcon.svg'
import backButton from '../assets/Common/backButton.svg'
import ShowImageNormally from "../components/Forms/ShowImageNormally";



export default function ViewBlog() {


    const navigate = useNavigate();
    const [currentImageIndex, setCurrentImageIndex] = useState(1)

    const authorNameRef = useRef();
    const authorDesignationRef = useRef();

    const titleRef = useRef();
    const shortDescriptionRef = useRef();
    const categoryRef = useRef();

    const tagRef = useRef();
    const dateRef = useRef();

    const descriptionRef = useRef();

    const metaTitleRef = useRef();
    const metaDescriptionRef = useRef();

    const [writerImages, setWriterImages] = useState()
    const [blogImage, setBlogImage] = useState()

    const pushPhoto = () => {
        console.log("")
    }

    // Introducing a parameter to refresh the UI
    const [dataLoaded, setDataLoaded] = useState(false)

    // getting Id from URL
    const { paramPropId } = useParams();

    useEffect(() => {

        // scrolling the windows up
        let centralWindow = document.querySelector(".centerPanel")
        centralWindow.scrollTo(0, 0);

        // getting data by ID and storing into temp var
        let getData = getBlogById(paramPropId)

        // setting all the normal Ref panel
        authorNameRef.current = getData.writer
        authorDesignationRef.current = getData.writertitle

        titleRef.current = getData.title
        shortDescriptionRef.current = getData.subtitle
        categoryRef.current = getData.category

        tagRef.current = arrayToString(getData.type)
        dateRef.current = formatDate(getData.date)

        descriptionRef.current = getData.description

        metaTitleRef.current = getData.meta_title
        metaDescriptionRef.current = getData.meta_description

        // setting Images to the respective ref
        getData?.images.forEach(element => {
            if (element.title === 'banner') {
                setBlogImage("https://data.thecorporatedge.com/cordocs/" + element?.url)
            }
            else {
                setWriterImages("https://data.thecorporatedge.com/cordocs/" + element?.url)
            }
        });

        // getting Index position of property
        setCurrentImageIndex(getBlogIndexById(paramPropId))
        setDataLoaded(true)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // converting array to string
    const arrayToString = (array) => {
        return array.join(", ");
    }

    // function to format date
    const formatDate = (inputDate) => {
        const date = new Date(inputDate);
        const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
        const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
        const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
        const suffix = day === '1' || day === '21' || day === '31' ? 'st' : day === '2' || day === '22' ? 'nd' : day === '3' || day === '23' ? 'rd' : 'th';
        return `${day}${suffix} ${month} ${year}`;
    }

    return (
        <>
            <div className="addBlogs lenis lenis-smooth">
                <div className="addBlogPanel">
                    {
                        dataLoaded ?
                            <>
                                <div className="headingTitle">
                                    <div className="backButton" onClick={() => navigate("/blogs")}>
                                        <img src={backButton} alt="backButton" />
                                        <span>Back</span>
                                    </div>
                                    <span>view your uploaded property</span>
                                </div>
                                <div className="dataTableStyles">
                                    <div className="entryOfTable">
                                        <div className="imageEntry">
                                            <span>{currentImageIndex + 1}.</span>
                                            <img src={blogImage} alt="bannerImage" />
                                        </div>
                                        <div className="nameEntry">
                                            {titleRef.current}
                                        </div>
                                        <div className="locationEntry">
                                            {dateRef.current}
                                        </div>
                                        <div className="locationEntry">
                                            {authorNameRef.current}
                                        </div>
                                        <div className="modifyingOption">
                                            <div className="allButtons">
                                                <div className="button editButton" onClick={() => navigate('/update-blog/' + paramPropId)}>
                                                    <span>edit</span>
                                                    <img src={editIcon} alt="editIcon" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="headingTitle">upload your blog image</div>
                                <div className="imageUploadBlogSection">
                                    <div className="mainPhoto">
                                        <ShowImageNormally labelText="photo 1*" imgNumber="1" imageRef={pushPhoto} preFilledImage={blogImage}></ShowImageNormally>
                                    </div>
                                    <div className="authorDetailsBox">
                                        <div className="authorImage">
                                            <ShowImageNormally labelText="Author Image" imgNumber="2"  preFilledImage={writerImages}></ShowImageNormally>
                                        </div>
                                        <div className="authorDetails">
                                            <InputSimpleBox
                                                labelText="Author name*"
                                                placeholder="Enter Author Name*"
                                                getRef={authorNameRef}
                                                priValue={authorNameRef.current}
                                                disabled={true}
                                            ></InputSimpleBox>
                                            <InputSimpleBox
                                                labelText="Author designation*"
                                                placeholder="Enter Author Designation"
                                                getRef={authorDesignationRef}
                                                priValue={authorDesignationRef.current}
                                                disabled={true}
                                            ></InputSimpleBox>
                                        </div>
                                    </div>
                                    <div className="midLine"></div>
                                    <div className="bottomLine"></div>
                                </div>
                                <div className="headingTitle">add your blog information</div>
                                <div className="fullTextBox">
                                    <InputSimpleBox
                                        labelText="title/ blog heading (H1) "
                                        placeholder="Enter Blog Heading"
                                        getRef={titleRef}
                                        priValue={titleRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                </div>
                                <div className="fullTextBox">
                                    <InputSimpleBox
                                        labelText="short blog description (subtitle)"
                                        placeholder="Enter Short Description"
                                        getRef={shortDescriptionRef}
                                        priValue={shortDescriptionRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                </div>
                                <div className="fullTextBox">
                                    <InputSimpleBox
                                        labelText="category* "
                                        placeholder="Enter Category"
                                        getRef={categoryRef}
                                        priValue={categoryRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                </div>
                                <div className="halfTextBox">
                                    <InputSimpleBox
                                        labelText="Blog tags*"
                                        placeholder="Enter Tags separated Via Comma"
                                        getRef={tagRef}
                                        priValue={tagRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                    <InputSimpleBox
                                        labelText="date*"
                                        placeholder="Enter Date"
                                        getRef={dateRef}
                                        priValue={dateRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                </div>
                                <div className="headingTitle">blog description / body*</div>
                                <div className="fullTextBox">
                                    <CkEditorBox
                                        labelText="blog description (subtitle)"
                                        placeholder={descriptionRef.current}
                                        getRef={descriptionRef}
                                    ></CkEditorBox>
                                </div>
                                <div className="headingTitle">SEO blog section</div>
                                <div className="halfTextBox">
                                    <InputSimpleBox
                                        labelText="meta title*"
                                        placeholder="Enter this page's Meta title"
                                        getRef={metaTitleRef}
                                        priValue={metaTitleRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                    <InputSimpleBox
                                        labelText="meta description*"
                                        placeholder="Enter this page's Meta description"
                                        getRef={metaDescriptionRef}
                                        priValue={metaDescriptionRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                </div>
                                <div className="submitButton">
                                    <div className="submitButtonBox" style={{ background: '#FFC42E', width: '15%' }} onClick={() => navigate('/update-blog/' + paramPropId)}>
                                        <span>Edit</span>
                                    </div>
                                </div>
                            </>
                            :
                            null
                    }
                </div>
            </div>
        </>
    )
}