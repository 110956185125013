import React, { useEffect } from "react";

import exportIcon from '../../assets/Common/export.svg'
import logoCE from '../../assets/Common/logoCE.png'

import "../../styles/common/welcomeAdmin.scss"

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { getAllProperties } from "../../services/PropertyServices";

export default function WelcomeAdmin({isExport}) {

    useEffect(() => {

        let centralWindow = document.querySelector(".centerPanel")
        centralWindow.scrollTo(0, 0);

    }, [])

    const downloadPropertyData = () => {
        let downloadFileData = getAllProperties()
        let newJson = downloadFileData.map((entry) => {
            return {
                Name: entry.name,
                Description: entry.description,
                "Floor No": entry.floor,
                "Building Name": entry.building,
                Address: entry.location,
                City: entry.city,
                State: entry.state,
                Pincode: entry.pincode,
                "Centre Contact": entry.contact,
                Longitude: entry.longitude,
                Latitude: entry.latitude,
                Airport: entry.airport,
                "Metro Station": entry.metrostation,
                "Famous Spot": entry.spot,
                Hotel: entry.station,
                "Private Work Space": getTrueOptions(entry.privatecabin),
                "Shared Workspace": getTrueOptions(entry.sharedworkspace),
                "Meeting Rooms": getTrueOptions(entry.meetingroom),
                "Virtual Offices": getTrueOptions(entry.virtualspace),
                Event: getTrueOptions(entry.events),
                Amenities: generateAmenitiesString(entry.amenities),
                PrimaryImage: "https://data.thecorporatedge.com/cordocs/" + entry.images[0]?.url
            }
        })
        exportPropertyData(newJson, "PropertyList")
    }


    const exportPropertyData = (getData, filename) => {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
    
        const worksheet = XLSX.utils.json_to_sheet(getData);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    
        const data = new Blob([excelBuffer], {
          type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, filename + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    const getTrueOptions = (options) => {
        let trueOptions = [];
        for (let option in options) {
            if (options[option] === true) {
                trueOptions.push(option);
            }
        }
        return trueOptions.join(', ');
    }

    const generateAmenitiesString = (amenities) => {
        let amenitiesNames = [];
        for (let amenity of amenities) {
            amenitiesNames.push(amenity.name);
        }
        return amenitiesNames.join(', ');
    }

    return (
        <>
            <div className="welcomeAdmin">
                <div className="innerWelcomeAdmin">
                    <div className="leftSidePanel">
                        <div className="avatarBox">
                            <img src={logoCE} alt="logo" />
                        </div>
                        <div className="infoBox">
                            <div className="nameAvatar">Hey, Super Admin</div>
                            <div className="niceMassage">Welcom back, nice to see you again !</div>
                        </div>
                    </div>
                    <div className="rightSidePanel">
                        {isExport==='true'? <div className="exportBox" onClick={() => downloadPropertyData()}>
                            <div className="massageExportBox">
                                Export your<br/> property details
                            </div>
                            <div className="iconExportBox">
                                <img src={exportIcon} alt="exportIcon" />
                            </div>
                        </div>
                        : null}
                        
                    </div>
                </div>
            </div>
        </>
    )
}