import React, { useEffect, useRef, useState } from "react";

import "../styles/Property/addProperty.scss";
import InputSimpleBox from "../components/Forms/InputSimpleBox";
import { getAllAmenities } from "../services/AmenitiesServices";
import { getPropertiesById, getPropertiesIndexById } from "../services/PropertyServices";
import { useNavigate, useParams } from "react-router-dom";
import ShowImageNormally from "../components/Forms/ShowImageNormally";


import editIcon from '../assets/Common/editIcon.svg'
import backButton from '../assets/Common/backButton.svg'

export default function ViewProperty() {

    const { paramPropId } = useParams();
    const navigate = useNavigate();


    // All Images Handling
    const [bulkImage, setBulkImage] = useState([])
    const [currentImageIndex, setCurrentImageIndex] = useState(1)

    const [dataLoaded, setDataLoaded] = useState(false)





    const nameRef = useRef();
    const descriptionRef = useRef();

    const contactRef = useRef();

    const latitudeRef = useRef();
    const longitudeRef = useRef();

    const airportRef = useRef();
    const metroStationRef = useRef();
    const spotRef = useRef();
    const stationRef = useRef();

    const floorRef = useRef();
    const buildingRef = useRef();
    const locationRef = useRef();
    const cityRef = useRef();
    const stateRef = useRef();
    const pincodeRef = useRef();



    // For Amenities
    const amenitiesValue = useRef([]);
    const [amenitiesState, setAmenitiesState] = useState([])


    useEffect(() => {

        let centralWindow = document.querySelector(".centerPanel")
        centralWindow.scrollTo(0, 0);


        // getting all Data
        let getData = getPropertiesById(paramPropId)


        // setting all Variables
        nameRef.current = getData.name;
        descriptionRef.current = getData.description;

        contactRef.current = getData.contact;

        latitudeRef.current = getData.latitude;
        longitudeRef.current = getData.longitude;

        airportRef.current = getData.airport;
        metroStationRef.current = getData.metrostation;
        spotRef.current = getData.spot;
        stationRef.current = getData.station;
        floorRef.current = getData.floor;
        buildingRef.current = getData.building;
        locationRef.current = getData.location;
        cityRef.current = getData.city;
        stateRef.current = getData.state;
        pincodeRef.current = getData.pincode;



        // setting all images
        if (getData.images.length > 0) {
            setBulkImage(getData.images.map((entry) => {
                return "https://data.thecorporatedge.com/cordocs/" + entry?.url
            }))
        }

        // setting all offerings

        updateOfferingVar('directorCabin', getData?.privatecabin?.directorcabin)
        updateOfferingVar('suites', getData?.privatecabin?.suites)
        updateOfferingVar('teamRoom', getData?.privatecabin?.teamroom)

        updateOfferingVar('dailyPass', getData?.sharedworkspace?.dailypass)
        updateOfferingVar('dedicateDesk', getData?.sharedworkspace?.dedicatedesk)

        updateOfferingVar('boardMeetingRoom', getData?.meetingroom?.boardmeetingroom)
        updateOfferingVar('functionRoom', getData?.meetingroom?.functionroom)
        updateOfferingVar('virtualRoom', getData?.meetingroom?.meetingroom)

        updateOfferingVar('ce_postal', getData?.virtualspace?.ce_postal)
        updateOfferingVar('ce_registered', getData?.virtualspace?.ce_registered)
        updateOfferingVar('virtual_office', getData?.virtualspace?.virtual_office)

        updateOfferingVar('event', getData?.events?.event)


        // updating all amenities
        let tempVar = getAllAmenities().map(entry => {
            let returnableItem = {
                ...entry,
                active: false
            }
            getData?.amenities.forEach(element => {
                if (element.id === entry.id) {
                    returnableItem.active = true
                }
            });
            return returnableItem
        })
        amenitiesValue.current = tempVar
        setAmenitiesState(tempVar)

        // getting Index position of property
        setCurrentImageIndex(getPropertiesIndexById(paramPropId))

        setDataLoaded(true)


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // For Offering
    const offeringVar = useRef({
        teamRoom: false,
        suites: false,
        directorCabin: false,

        dailyPass: false,
        dedicateDesk: false,

        boardMeetingRoom: false,
        functionRoom: false,
        virtualRoom: false,

        ce_postal: false,
        ce_registered: false,
        virtual_office: false,

        event: false,
    });

    const [offeringState, setOfferingState] = useState({
        ...offeringVar.current,
    });

    const updateOfferingVar = (param, states) => {
        offeringVar.current = { ...offeringVar.current, [param]: states };
        setOfferingState({ ...offeringVar.current });
    };

    return (
        <>

            <div className="addProperty lenis lenis-smooth">
                <div className="addPropertyPanel">
                    <div className="headingTitle">
                        <div className="backButton" onClick={() => navigate("/")}>
                            <img src={backButton} alt="backButton" />
                            <span>Back</span>
                        </div>
                        <span>view your uploaded property</span>
                    </div>
                    <div className="dataTableStyles">
                        <div className="entryOfTableProperty">
                            <div className="imageEntry">
                                <span>{currentImageIndex + 1}.</span>
                                <img src={bulkImage[0]} alt="bannerImage" />
                            </div>
                            <div className="nameEntry">
                                {nameRef.current}
                            </div>
                            <div className="locationEntry">
                                {floorRef.current + ", " + buildingRef.current + ", " + locationRef.current + ", " + cityRef.current + ", " + stateRef.current}
                            </div>
                            <div className="modifyingOption">
                                <div className="allButtons">
                                    <div className="button editButton" onClick={() => navigate('/update-property/' + paramPropId)}>
                                        <span>edit</span>
                                        <img src={editIcon} alt="editIcon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="headingTitle">view your uploaded property details</div>
                    <div className="imageUploadSection">
                        {bulkImage.map((entry, index) => <ShowImageNormally key={index} labelText={"photo " + (index + 1) + "*"} preFilledImage={entry}></ShowImageNormally>)}
                        {/* <UploadBoxBulk labelText="Bulk Upload" imgNumber="0" imageRef={pushPhoto}></UploadBoxBulk> */}
                    </div>
                    {
                        dataLoaded ?
                            <>
                                <div className="fullTextBox">
                                    <InputSimpleBox
                                        labelText="your property name* ( h1 heading )"
                                        placeholder="Enter Property Name"
                                        priValue={nameRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                </div>
                                <div className="fullTextBox">
                                    <InputSimpleBox
                                        labelText="your property description*"
                                        placeholder="Enter Property Description"
                                        priValue={descriptionRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                </div>
                                <div className="halfTextBox">
                                    <InputSimpleBox
                                        labelText="Floor No*"
                                        placeholder="Enter Property's Floor No"
                                        priValue={floorRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                    <InputSimpleBox
                                        labelText="Building Name* "
                                        placeholder="Enter Property's Building Name"
                                        priValue={buildingRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                </div>
                                <div className="fullTextBox">
                                    <InputSimpleBox
                                        labelText="property Address*"
                                        placeholder="Enter Property Address"
                                        priValue={locationRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                </div>
                                <div className="halfTextBox">
                                    <InputSimpleBox
                                        labelText="Pincode*"
                                        placeholder="Enter Pincode"
                                        priValue={pincodeRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                    <InputSimpleBox
                                        labelText="City* "
                                        placeholder="Enter City"
                                        priValue={cityRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                </div>
                                <div className="fullTextBox"></div>
                                <div className="halfTextBox">
                                    <InputSimpleBox
                                        labelText="State*"
                                        placeholder="Enter State"
                                        priValue={stateRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                    <InputSimpleBox
                                        labelText="centre contact no. "
                                        placeholder="Enter Contact Number"
                                        priValue={contactRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                </div>

                                <div className="headingTitle">offerings* </div>
                                <div className="offeringSection">
                                    <div className="innerOfferingSection">
                                        <div className="offeringSectionsBox">
                                            <div className="offeringSectionHeading">Private work space</div>
                                            <div className="offeringSectionAllItemsBox">
                                                <div
                                                    className={
                                                        offeringState.directorCabin
                                                            ? "offeringSectionItem activeOfferingSectionItem"
                                                            : "offeringSectionItem"
                                                    }
                                                >
                                                    <div className="selectedOrNotBox"></div>
                                                    <div className="labelForSelectedOrNotBox">
                                                        Director Cabin
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        offeringState.suites
                                                            ? "offeringSectionItem activeOfferingSectionItem"
                                                            : "offeringSectionItem"
                                                    }
                                                >
                                                    <div className="selectedOrNotBox"></div>
                                                    <div className="labelForSelectedOrNotBox">Suites</div>
                                                </div>
                                                <div
                                                    className={
                                                        offeringState.teamRoom
                                                            ? "offeringSectionItem activeOfferingSectionItem"
                                                            : "offeringSectionItem"
                                                    }
                                                >
                                                    <div className="selectedOrNotBox"></div>
                                                    <div className="labelForSelectedOrNotBox">Team Room</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="offeringSectionsBox">
                                            <div className="offeringSectionHeading">Shared workspace</div>
                                            <div className="offeringSectionAllItemsBox">
                                                <div
                                                    className={
                                                        offeringState.dedicateDesk
                                                            ? "offeringSectionItem activeOfferingSectionItem"
                                                            : "offeringSectionItem"
                                                    }
                                                >
                                                    <div className="selectedOrNotBox"></div>
                                                    <div className="labelForSelectedOrNotBox">
                                                        Dedicated Desk
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        offeringState.dailyPass
                                                            ? "offeringSectionItem activeOfferingSectionItem"
                                                            : "offeringSectionItem"
                                                    }
                                                >
                                                    <div className="selectedOrNotBox"></div>
                                                    <div className="labelForSelectedOrNotBox">Daily Pass</div>
                                                </div>
                                            </div>
                                            <div className="separator"></div>
                                        </div>
                                        <div className="offeringSectionsBox">
                                            <div className="offeringSectionHeading">Meeting Rooms</div>
                                            <div className="offeringSectionAllItemsBox">
                                                <div
                                                    className={
                                                        offeringState.boardMeetingRoom
                                                            ? "offeringSectionItem activeOfferingSectionItem"
                                                            : "offeringSectionItem"
                                                    }
                                                >
                                                    <div className="selectedOrNotBox"></div>
                                                    <div className="labelForSelectedOrNotBox">
                                                        Board Meeting Room
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        offeringState.functionRoom
                                                            ? "offeringSectionItem activeOfferingSectionItem"
                                                            : "offeringSectionItem"
                                                    }
                                                >
                                                    <div className="selectedOrNotBox"></div>
                                                    <div className="labelForSelectedOrNotBox">
                                                        Function Room
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        offeringState.virtualRoom
                                                            ? "offeringSectionItem activeOfferingSectionItem"
                                                            : "offeringSectionItem"
                                                    }
                                                >
                                                    <div className="selectedOrNotBox"></div>
                                                    <div className="labelForSelectedOrNotBox">
                                                        Virtual Room
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="separator"></div>
                                        </div>
                                        <div className="offeringSectionsBox">
                                            <div className="offeringSectionHeading">Virtual Offices</div>
                                            <div className="offeringSectionAllItemsBox">
                                                <div
                                                    className={
                                                        offeringState.ce_postal
                                                            ? "offeringSectionItem activeOfferingSectionItem"
                                                            : "offeringSectionItem"
                                                    }
                                                >
                                                    <div className="selectedOrNotBox"></div>
                                                    <div className="labelForSelectedOrNotBox">CE_Postal</div>
                                                </div>
                                                <div
                                                    className={
                                                        offeringState.ce_registered
                                                            ? "offeringSectionItem activeOfferingSectionItem"
                                                            : "offeringSectionItem"
                                                    }
                                                >
                                                    <div className="selectedOrNotBox"></div>
                                                    <div className="labelForSelectedOrNotBox">
                                                        CE_Registered
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        offeringState.virtual_office
                                                            ? "offeringSectionItem activeOfferingSectionItem"
                                                            : "offeringSectionItem"
                                                    }
                                                >
                                                    <div className="selectedOrNotBox"></div>
                                                    <div className="labelForSelectedOrNotBox">Virtual Office</div>
                                                </div>
                                            </div>
                                            <div className="separator"></div>
                                        </div>
                                        <div className="offeringSectionsBox">
                                            <div className="offeringSectionHeading">Event</div>
                                            <div className="offeringSectionAllItemsBox">
                                                <div
                                                    className={
                                                        offeringState.event
                                                            ? "offeringSectionItem activeOfferingSectionItem"
                                                            : "offeringSectionItem"
                                                    }
                                                >
                                                    <div className="selectedOrNotBox"></div>
                                                    <div className="labelForSelectedOrNotBox">Event</div>
                                                </div>
                                            </div>
                                            <div className="separator"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="headingTitle">Amenities* </div>
                                <div className="amenitiesSection">
                                    <div className="offeringSectionHeading">
                                        Select your Available amenities
                                    </div>
                                    <div className="innerAmenitiesSection">
                                        {amenitiesState.map(entry => {
                                            return <div
                                                className={
                                                    entry.active
                                                        ? "amenitiesSectionItem activeAmenitiesSectionItem"
                                                        : "amenitiesSectionItem"
                                                }
                                            >
                                                <div className="selectedOrNotBox"></div>
                                                <div className="imgForSelectedOrNotBox">
                                                    <img src={"https://data.thecorporatedge.com/cordocs/" + entry.fileData.url} alt="" />
                                                </div>
                                                <div className="labelForSelectedOrNotBox">{entry.name}</div>
                                            </div>
                                        })}
                                    </div>
                                </div>

                                <div className="headingTitle">Location </div>
                                <div className="halfTextBox">
                                    <InputSimpleBox
                                        labelText="enter your location longitude*"
                                        placeholder="e.g. 41.233244"
                                        priValue={longitudeRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                    <InputSimpleBox
                                        labelText="enter your location LATITUDE*"
                                        placeholder="e.g. 73.343323"
                                        priValue={latitudeRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                </div>

                                <div className="headingTitle">What's nearby? </div>
                                <div className="halfTextBox">
                                    <InputSimpleBox
                                        labelText="airport"
                                        placeholder="Near By Airport"
                                        priValue={airportRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                    <InputSimpleBox
                                        labelText="metro station "
                                        placeholder="Near By Metro Station"
                                        priValue={metroStationRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                </div>
                                <div className="fullTextBox"></div>
                                <div className="halfTextBox">
                                    <InputSimpleBox
                                        labelText="famous spot"
                                        placeholder="Near By Famous Spot"
                                        priValue={spotRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                    <InputSimpleBox
                                        labelText="hotel"
                                        placeholder="Near By Hotel"
                                        priValue={stationRef.current}
                                        disabled={true}
                                    ></InputSimpleBox>
                                </div>
                                <div className="submitButton">
                                    <div className="submitButtonBox" style={{ background: '#FFC42E', width: '15%' }} onClick={() => navigate('/update-property/' + paramPropId)}>
                                        <span>Edit</span>
                                    </div>
                                </div>
                            </>
                            : null
                    }

                </div>
            </div>
        </>
    );
}
