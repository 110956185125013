import axios from "axios";

var allBlog = [];


export function refreshAllBlogs() {

    axios({

        // Endpoint to send files
        url: "https://data.thecorporatedge.com/CORManager/blog/",
        method: "GET",

    })

        // Handle the response from backend here
        .then((res) => {
            allBlog = res.data
            return true
        })

        // Catch errors if any
        .catch((err) => { console.log(err); return false });
}

export function getAllBlogs() {
    return allBlog
}

export function getBlogsLength() {
    return allBlog.length
}

export function getBlogById(getId) {
    let filterOutData = allBlog.filter(entry => entry.id === getId)
    if (filterOutData) {
        return filterOutData[0]
    }
    else {
        return []
    }
}

export function getBlogIndexById(getId) {
    let filterOutData = 0
    allBlog.forEach((entry, index) => {
        if (entry.id === getId) {
            filterOutData = index
        }
    })
    return filterOutData
}

export function deleteBlog(blogId) {
    let token = sessionStorage.getItem('egdeetaroprocdnauiv')

    return axios({

        // Endpoint to send files
        url: "https://data.thecorporatedge.com/CORManager/blog/delete/" + blogId,
        method: "DELETE",
        // adding authentication
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function deleteBlogImage(imageId) {
    let token = sessionStorage.getItem('egdeetaroprocdnauiv')

    return axios({

        // Endpoint to send files
        url: "https://data.thecorporatedge.com/CORManager/blog/delete/image/" + imageId,
        method: "DELETE",
        // adding authentication
        headers: { Authorization: `Bearer ${token}` }
    })
}

export function editorChoiceCount() {
    return allBlog.filter((value) => value.ediorpick === "YES").length
}

export function bannerSelectionCount() {
    return allBlog.filter((value) => value.bannerselection === "YES").length
}

export function inStreamCount() {
    return allBlog.filter((value) => value.instream === "YES").length
}

export function editorChoiceCountWithException(id) {
    return allBlog.filter((value) => value.ediorpick === "YES" && value.id !== id).length
}

export function bannerSelectionCountWithException(id) {
    return allBlog.filter((value) => value.bannerselection === "YES" && value.id !== id).length
}

export function inStreamCountWithException(id) {
    return allBlog.filter((value) => value.instream === "YES" && value.id !== id).length
}