import React from "react";

import '../../styles/forms/uploadBox.scss'
import uploadAddButton from '../../assets/Common/uploadAddButton.svg'
import adminImage from '../../assets/blog/author.png'

export default function ShowImageNormally(props) {

    const setIsImageSelected = () => {props.clickAction()}

    return (
        <>
            <div className="uploadItemBox">
                <div className="labelItem">{props.labelText}</div>
                <div className="afterUploadBox">
                    {props.preFilledImage ?
                        <img src={props.preFilledImage} className="imagePreviewSrc" alt="imagePreviewSrc" />
                        :
                        <img src={adminImage} className="imagePreviewSrc" style={{ objectFit: "none" }} alt="imagePreviewSrc" />
                    }
                    <img src={uploadAddButton} className="uploadAddButton" style={{ transform: "translate(-50%, -50%) rotate(45deg)", bottom: 'initial', top: "10%", left: "95%" }} onClick={() => setIsImageSelected()} alt="uploadAddButton" />
                </div>
            </div>
        </>
    )
}