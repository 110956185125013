import React, { useEffect, useState } from "react";

import logoCOE from '../../assets/logo.png'
import logoutIcon from '../../assets/navbar/logout.png'
import adminIcon from '../../assets/navbar/admin.png'
import logoCESmall from '../../assets/Common/logoCE.png'

import { useNavigate } from "react-router-dom";

export default function NavbarOfAdmin({ setLogin }) {

    const [openDropdown, setOpenDropdown] = useState(false)

    const [isAdmin, setIsAdmin] = useState(false)

    const navigate = useNavigate();

    const moveToLogin = () => {
        localStorage.clear()
        sessionStorage.clear()
        setLogin(false)
        setTimeout(() => {
            navigate('/')
        }, 500);
    }

    useEffect(() => {
        let roles = sessionStorage.getItem("roles")
        if (roles === "ROLE_ADMIN") {
            setIsAdmin(true)
        }
        if (roles === "ROLE_PROP_ACCESS") {
            setIsAdmin(false)
        }
        if (roles === "ROLE_BLOG_ACCESS") {
            setIsAdmin(false)
        }
    }, []);

    return (
        <>
            <div className="navbarOfAdmin" onMouseLeave={() => setOpenDropdown(false)}>
                <div className="innerSectionOfNavbar">
                    <div className="logoBox">
                        <img src={logoCOE} alt="vuiLogo" />
                    </div>
                    <div className="rightBox" onMouseEnter={() => setOpenDropdown(true)} >
                        <div className="profileIcon">
                            <img src={logoCESmall} alt="adminIcon" />
                        </div>
                        {openDropdown &&
                            <div className="dropDown">
                                {isAdmin ?
                                    <div className="optionPanel" onClick={() => { navigate('/users'); setOpenDropdown(false) }}>
                                        <img src={adminIcon} alt="adminIcon" />
                                        <span>User Management</span>
                                    </div>
                                    : null}
                                <div className="optionPanel" onClick={() => moveToLogin()}>
                                    <img src={logoutIcon} alt="logoutIcon" />
                                    <span>Logout</span>
                                </div>

                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}