import React, { useEffect, useRef, useState } from "react";

import changesIcon from "../assets/Common/changesIcon.svg";

import "../styles/Property/addProperty.scss";
import InputSimpleBox from "../components/Forms/InputSimpleBox";
import { useNavigate } from "react-router-dom";
import { addNewUser, refreshAllUsers } from "../services/UserServices";

export default function AddUser() {

    const [waitingScreen, setWaitingScreen] = useState(false)

    const [dropAdmin, setDropAdmin] = useState(false)

    const [selectedOption, setSelectedOption] = useState("")
    const selectedOptionRef = useRef("")

    const [propertyAccess, setPropertyAccess] = useState(false)
    const propertyAccessRef = useRef(false)
    const [blogAccess, setBlogAccess] = useState(false)
    const blogAccessRef = useRef(false)

    const navigate = useNavigate();





    const nameRef = useRef();
    const userNameRef = useRef();

    const emailRef = useRef();

    const passwordRef = useRef();
    const roleRef = useRef();

    const [error, setError] = React.useState({
        name: "",
        username: "",
        email: "",
        password: "",
        role: ""
    });



    useEffect(() => {

        let centralWindow = document.querySelector(".centerPanel")
        centralWindow.scrollTo(0, 0);

    }, [])


    const selectedDropdown = (index) => {
        if (index === 1) {
            setSelectedOption("Admin")
            selectedOptionRef.current = "Admin"
        }
        if (index === 2) {
            setSelectedOption("Non - Admin")
            selectedOptionRef.current = "NonAdmin"
        }
        setDropAdmin(false)
    }

    const selectedRole = (index) => {
        if (index === 1) {
            propertyAccessRef.current = !propertyAccessRef.current
            setPropertyAccess(propertyAccessRef.current)
        }
        if (index === 2) {
            blogAccessRef.current = !blogAccessRef.current
            setBlogAccess(blogAccessRef.current)
        }
    }

    // Now Start 

    const validateInputs = () => {
        let errors = {};

        if (!nameRef.current.value) errors.name = "User name is required";
        if (!userNameRef.current.value) errors.username = "Username is required";
        if (!emailRef.current.value) errors.email = "Email is required";
        if (!passwordRef.current.value) errors.password = "Password is required";

        if (selectedOptionRef.current === "Admin") {
            roleRef.current = "admin"
        } else {
            if (propertyAccessRef.current && blogAccessRef.current) {
                roleRef.current = "user"
            }
            else if (propertyAccessRef.current) {
                roleRef.current = "prop_access"
            }
            else if (blogAccessRef.current) {
                roleRef.current = "blog_access"
            }
            else {
                errors.role = "Please select Proper Role";
            }
        }

        setError(errors);
        return Object.keys(errors).length === 0;
    };

    const uploadDataNow = () => {
        if (validateInputs()) {
            setWaitingScreen(true)

            let sendableData = {
                name: nameRef.current?.value,
                username: userNameRef.current?.value,
                password: passwordRef.current?.value,
                email: emailRef.current?.value,
                roles: [roleRef.current],
            };


            addNewUser(sendableData).then(res => {
                refreshAllUsers();
                setTimeout(() => {
                    navigate('/users')
                }, 1000);
            }).catch(err => { console.log(err); setWaitingScreen(false) })

        }
    };

    return (
        <>
            <div className="addProperty lenis lenis-smooth">
                <div className="addPropertyPanel">
                    <div className="headingTitle">Provide User details</div>
                    <div className="halfTextBox">
                        <div className={error.name ? "error" : ""}>
                            <InputSimpleBox
                                labelText="Name*"
                                placeholder="Enter User's Name"
                                getRef={nameRef}
                            ></InputSimpleBox>
                            {error.name && <div className="errorBar">{error.name}</div>}
                        </div>
                        <div className={error.username ? "error" : ""}>
                            <InputSimpleBox
                                labelText="Username* "
                                placeholder="Enter username used for login"
                                getRef={userNameRef}
                            ></InputSimpleBox>
                            {error.username && <div className="errorBar">{error.username}</div>}
                        </div>
                    </div>
                    <div className="fullTextBox"></div>
                    <div className="halfTextBox">
                        <div className={error.email ? "error" : ""}>
                            <InputSimpleBox
                                labelText="Email* "
                                placeholder="Enter email of user"
                                getRef={emailRef}
                            ></InputSimpleBox>
                            {error.email && <div className="errorBar">{error.email}</div>}
                        </div>
                        <div className={error.password ? "error" : ""}>
                            <InputSimpleBox
                                labelText="Password*"
                                placeholder="Enter Login password"
                                getRef={passwordRef}
                            ></InputSimpleBox>
                            {error.password && <div className="errorBar">{error.password}</div>}
                        </div>
                    </div>
                    <div className="fullTextBox">
                        <div className="inputDropdown" onMouseLeave={() => setDropAdmin(false)}>
                            <div className="dropdown">
                                <div className="dropbtn" onMouseEnter={() => setDropAdmin(true)}>{selectedOption ? selectedOption : "Select Role"}</div>
                                {dropAdmin &&
                                    <div className="dropdown-content">
                                        <div className="dropdownItem" onClick={() => selectedDropdown(1)}>Admin</div>
                                        <div className="dropdownItem" onClick={() => selectedDropdown(2)}>Non Admin</div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                            {error.role && <div className="errorBar">{error.role}</div>}
                    {selectedOption === "Non - Admin" &&
                        <>
                            <div className="headingTitle">Select Role* </div>
                            <div className="accessSection">
                                <div className="innerAccessSection">
                                    <div
                                        className={
                                            propertyAccess
                                                ? "accessSectionItem activeAccessSectionItem"
                                                : "accessSectionItem"
                                        }
                                        onClick={() => selectedRole(1)}
                                    >
                                        <div className="selectedOrNotBox"></div>
                                        <div className="labelForSelectedOrNotBox">Property Access</div>
                                    </div>
                                    <div
                                        className={
                                            blogAccess
                                                ? "accessSectionItem activeAccessSectionItem"
                                                : "accessSectionItem"
                                        }
                                        onClick={() => selectedRole(2)}
                                    >
                                        <div className="selectedOrNotBox"></div>
                                        <div className="labelForSelectedOrNotBox">Blog Access</div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    <div className="submitButton">
                        <div className="submitButtonBox" onClick={() => uploadDataNow()}>
                            <span>save all changes</span>
                            <img src={changesIcon} alt="changesIcon" />
                        </div>
                    </div>
                </div>
            </div>
            {waitingScreen &&
                <div className="notificationFormProperty">
                    <div className="mainBoxNotificationBox confirmationBox">
                        <div className="iconOfNotificationBox"></div>
                        <div className="textBoxOfNotificationBox">
                            <div className="headingOfNotification">
                                Please Wait
                            </div>
                            <div className="subTextOfNotification">
                                We are uploading your Data
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
