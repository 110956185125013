import React, { useEffect, useState } from "react";

import propertyListCard from '../../assets/property/propertyListCard.png'
import propertyAddCard from '../../assets/property/propertyAddCard.png'
import addNewLargeIcon from '../../assets/Common/addNewLargeIcon.svg'

import "../../styles/Property/propertyTotalCount.scss"
import { getPropertiesLength } from "../../services/PropertyServices";
import { useNavigate } from "react-router-dom";

export default function PropertyTotalCount() {

    const navigate = useNavigate();
    
    const [propertyCount, setPropertyCount] = useState([])

    useEffect(() => {

        setPropertyCount(getPropertiesLength())

        setInterval(() => {
            setPropertyCount(getPropertiesLength())
        }, 3000);

    }, [])

    return (
        <>
            <div className="propertyTotalCount">
                <div className="listOfCards">
                    <div className="listingCard">
                        <div className="backgroundImage">
                            <img src={propertyListCard} alt="propertyListCard" />
                        </div>
                        <div className="mainContentCardList">
                            <div className="countNumber">{propertyCount}</div>
                            <div className="aboutCount">Properties have been listed till now</div>
                        </div>
                    </div>
                    <div className="addListCard" onClick={() => navigate('/new-property')}>
                        <div className="backgroundImage">
                            <img src={propertyAddCard} alt="propertyAddCard" />
                        </div>
                        <div className="centerCardInfo">
                            <div className="aboutCard">Add New<br />Property</div>
                            <div className="leonOfCard">
                                <img src={addNewLargeIcon} alt="addNewLargeIcon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}