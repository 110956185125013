import React, { useEffect, useRef, useState } from "react";

import changesIcon from "../assets/Common/changesIcon.svg";

import "../styles/Property/addProperty.scss";
import UploadBox from "../components/Forms/UploadBox";
import InputSimpleBox from "../components/Forms/InputSimpleBox";
import { getAllAmenities } from "../services/AmenitiesServices";
import {
  addNewProperty,
  refreshAllProperties,
} from "../services/PropertyServices";
import UploadBoxBulk from "../components/Forms/UploadBoxBulk";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getAllLocation } from "../services/LocationServices";
import InputDropDownBox from "../components/Forms/InputDropDownBox";

export default function AddProperty() {
  const [waitingScreen, setWaitingScreen] = useState(false);

  const addPropertyPanelRef = useRef();

  const navigate = useNavigate();

  // Upload Related Handling
  const uploadingImageCount = useRef(0);

  // All Images Handling
  const image1 = useRef();
  const image2 = useRef();
  const image3 = useRef();
  const image4 = useRef();

  const [allLocation, setAllLocation] = useState([]);
  const [indiaState, setIndiaState] = useState("");

  const [bulkImage, setBulkImage] = useState([]);
  const bulkImageRef = useRef([] | null);

  const pushPhoto = (file, number) => {
    if (number === 1) {
      image1.current = file;
    } else if (number === 2) {
      image2.current = file;
    } else if (number === 3) {
      image3.current = file;
    } else if (number === 4) {
      image4.current = file;
    } else if (number === 0) {
      setBulkImage((priv) => {
        let imagesArray = [...priv, file];
        bulkImageRef.current = imagesArray;
        return imagesArray;
      });
    } else {
      let index = Number(number) - 5;
      bulkImageRef.current[index] = file;
    }
  };

  const nameRef = useRef();
  const descriptionRef = useRef();

  const contactRef = useRef();

  const latitudeRef = useRef();
  const longitudeRef = useRef();

  const airportRef = useRef();
  const metroStationRef = useRef();
  const spotRef = useRef();
  const stationRef = useRef();

  const floorRef = useRef();
  const buildingRef = useRef();
  const bunitRef = useRef();
  const locationRef = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const pincodeRef = useRef();

  const [error, setError] = React.useState({
    name: "",
    description: "",
    contact: "",
    latitude: "",
    longitude: "",
    airport: "",
    metroStation: "",
    spot: "",
    station: "",
    floor: "",
    building: "",
    location: "",
    city: "",
    state: "",
    pinCode: "",
    image1: "",
    image2: "",
    image3: "",
    image4: "",
  });

  // For Amenities
  const amenitiesValue = useRef([]);
  const [amenitiesState, setAmenitiesState] = useState([]);

  useEffect(() => {
    setAllLocation(getAllLocation());

    let centralWindow = document.querySelector(".centerPanel");
    centralWindow.scrollTo(0, 0);

    let tempVar = getAllAmenities().map((entry) => {
      return {
        ...entry,
        active: false,
      };
    });
    amenitiesValue.current = tempVar;
    setAmenitiesState(tempVar);
  }, []);

  const updateAmenitiesVar = (param) => {
    amenitiesValue.current = amenitiesValue.current.map((entry) => {
      if (entry.name === param) {
        entry.active = !entry.active;
      }
      return entry;
    });
    setAmenitiesState(amenitiesValue.current);
  };

  // For Offering
  const offeringVar = useRef({
    teamRoom: false,
    suites: false,
    directorCabin: false,

    dailyPass: false,
    dedicateDesk: false,

    boardMeetingRoom: false,
    functionRoom: false,
    virtualRoom: false,

    ce_postal: false,
    ce_registered: false,
    virtual_office: false,

    event: false,
  });

  const [offeringState, setOfferingState] = useState({
    ...offeringVar.current,
  });

  const updateOfferingVar = (param) => {
    offeringVar.current = {
      ...offeringVar.current,
      [param]: !offeringVar.current[param],
    };
    setOfferingState({ ...offeringVar.current });
  };

  // Now Start

  const validateInputs = () => {
    let errors = {};

    if (!nameRef.current.value) errors.name = "Property name is required";
    if (!descriptionRef.current.value)
      errors.description = "Property Description is required";
    if (!latitudeRef.current.value)
      errors.latitude = "Latitude is required to show map";
    if (!longitudeRef.current.value)
      errors.longitude = "Longitude is required to show map";
    if (!floorRef.current.value) errors.floor = "Address is required";
    if (!buildingRef.current.value) errors.building = "Address is required";
    if (!bunitRef.current.value) errors.bunit = "bunit is required";
    if (!locationRef.current.value) errors.location = "Address is required";
    if (!cityRef.current) errors.city = "City is required";
    if (!stateRef.current.value) errors.state = "Address is required";
    if (!pincodeRef.current.value)
      errors.pinCode = "Pin code of this location is required";
    if (!image1.current) errors.image1 = "Photo 1 is required";
    if (!image2.current) errors.image2 = "Photo 2 is required";
    if (!image3.current) errors.image3 = "Photo 3 is required";
    if (!image4.current) errors.image4 = "Photo 4 is required";

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const uploadDataNow = () => {
    if (validateInputs()) {
      setWaitingScreen(true);

      let sendableData = {
        name: nameRef.current?.value,
        floor: floorRef.current?.value,
        building: buildingRef.current?.value,
        bunit: bunitRef.current?.value,
        location: locationRef.current?.value,
        pincode: pincodeRef.current?.value,
        city: cityRef.current,
        state: stateRef.current?.value,
        country: "INDIA",
        description: descriptionRef.current?.value,
        airport: airportRef.current?.value,
        station: stationRef.current?.value,
        metrostation: metroStationRef.current?.value,
        spot: spotRef.current?.value,
        latitude: latitudeRef.current?.value,
        longitude: longitudeRef.current?.value,
        contact: contactRef.current?.value,
        privatecabin: {
          teamroom: offeringVar.current.teamRoom,
          suites: offeringVar.current.suites,
          directorcabin: offeringVar.current.directorCabin,
        },
        sharedworkspace: {
          dedicatedesk: offeringVar.current.dedicateDesk,
          dailypass: offeringVar.current.dailyPass,
        },
        meetingroom: {
          boardmeetingroom: offeringVar.current.boardMeetingRoom,
          functionroom: offeringVar.current.functionRoom,
          virtualroom: offeringVar.current.virtualRoom,
        },
        virtualspace: {
          virtual_office: offeringVar.current.virtual_office,
          ce_postal: offeringVar.current.ce_postal,
          ce_registered: offeringVar.current.ce_registered,
        },
        events: {
          event: offeringVar.current.event,
        },
        amenities: [],
      };

      sendableData.amenities = amenitiesValue.current
        .filter((entry) => entry.active === true)
        .map((entry) => {
          return { name: entry.name };
        });

      addNewProperty(sendableData)
        .then((res) => {
          refreshAllProperties();
          uploadAllImages(res.data.id);
        //   let token = sessionStorage.getItem('egdeetaroprocdnauiv')

           axios({
      
              // Endpoint to send files
              url: "https://ce.extensionerp.com/api/resource/Business Unit",
              method: "POST",
              // Attaching the form data
              data: { 

                "location":bunitRef.current?.value
            
            },
              // adding authentication
              headers: { Authorization: `token f8e63323af0ebf4:08ae8d95c506efd` }
          })
          .then((res)=>{
           console.log(res)
          })
          .catch((err)=>{
console.log(err)
          })
            



        })
        .catch((err) => {
          console.log(err);
          setWaitingScreen(false);
        });
    } else addPropertyPanelRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const uploadAllImages = (propId) => {
    uploadImage(
      propId,
      image1.current,
      nameRef.current?.value + "a1212Image1a1212"
    );
    setTimeout(() => {
      uploadImage(
        propId,
        image2.current,
        nameRef.current?.value + "a1212Image2a1212"
      );
    }, 1000);
    setTimeout(() => {
      uploadImage(
        propId,
        image3.current,
        nameRef.current?.value + "a1212Image3a1212"
      );
    }, 2000);
    setTimeout(() => {
      uploadImage(
        propId,
        image4.current,
        nameRef.current?.value + "a1212Image4a1212"
      );
    }, 3000);
    setTimeout(() => {
      bulkImage.forEach((entry) => {
        uploadImage(propId, entry, nameRef.current?.value + "o1212Extra1212");
      });
      refreshAllProperties();
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }, 4000);
  };

  const uploadImage = (propId, file, fileName) => {
    let formData = new FormData();
    let token = "Bearer " + sessionStorage.getItem("egdeetaroprocdnauiv");

    // Adding files to the formdata
    formData.append("file", file);
    formData.append("title", fileName);

    axios({
      // Endpoint to send files
      url: "https://data.thecorporatedge.com/CORManager/property/image/" + propId,
      method: "POST",
      headers: {
        // Add any auth token here
        authorization: token,
      },

      // Attaching the form data
      data: formData,
    })
      // Handle the response from backend here
      .then((res) => {
        uploadingImageCount.current++;
      });
  };

  // This function will be called whenever pincode changes
  const [pinCodeState, setPinCodeState] = useState("");
  const [pinCodeStateResult, setPinCodeStateResult] = useState("Select City");
  const [pinCodeIDStateResult, setPinCodeIDStateResult] = useState("");

  useEffect(() => {
    // console.log(pinCodeState);

    if (pinCodeState.length > 6) {
      alert("Please enter a valid pincode");
    }
    if (pinCodeState.length === 6) {
      axios
        .get(`https://api.postalpincode.in/pincode/${pinCodeState}`)
        .then((response) => {
          const data = response.data;
          if (data[0].Status === "Success") {
            const cityName = data[0].PostOffice[0].District;
            const blockName = data[0].PostOffice[0].Block;
            if (blockName === "New Delhi") {
              allLocation.forEach((entry) => {
                if (entry.city === "New Delhi") {
                  setPinCodeIDStateResult(entry.city);
                  setIndiaState(entry.state);
                }
              });
              setPinCodeStateResult("New Delhi");
            } else if (
              cityName === "Bangalore" ||
              cityName === "Bangalore Rural"
            ) {
              allLocation.forEach((entry) => {
                if (entry.city === "Bengaluru") {
                  setPinCodeIDStateResult(entry.city);
                  setIndiaState(entry.state);
                }
              });
              setPinCodeStateResult("Bengaluru");
            } else if (cityName === "Gurgaon") {
              allLocation.forEach((entry) => {
                if (entry.city === "Gurugram") {
                  setPinCodeIDStateResult(entry.city);
                  setIndiaState(entry.state);
                }
              });
              setPinCodeStateResult("Gurugram");
            }
          } else {
            console.log("Pincode not found");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [allLocation, pinCodeState]);

  const changeStateUsingCity = (cityId) => {
    console.log(cityId);
    allLocation.forEach((entry) => {
      if (entry.city === cityId) {
        setIndiaState(entry.state);
      }
    });
  };

  return (
    <>
      <div className="addProperty lenis lenis-smooth">
        <div className="addPropertyPanel" ref={addPropertyPanelRef}>
          <div className="headingTitle">upload your property image</div>
          <div className="imageUploadSection">
            <div className={error.image1 ? "error" : ""}>
              <UploadBox
                labelText="photo 1*"
                imgNumber="1"
                imageRef={pushPhoto}
              ></UploadBox>
            </div>
            <div className={error.image2 ? "error" : ""}>
              <UploadBox
                labelText="photo 2*"
                imgNumber="2"
                imageRef={pushPhoto}
              ></UploadBox>
            </div>
            <div className={error.image3 ? "error" : ""}>
              <UploadBox
                labelText="photo 3*"
                imgNumber="3"
                imageRef={pushPhoto}
              ></UploadBox>
            </div>
            <div className={error.image4 ? "error" : ""}>
              <UploadBox
                labelText="photo 4*"
                imgNumber="4"
                imageRef={pushPhoto}
              ></UploadBox>
            </div>

            {bulkImage.map((entry, index) => (
              <UploadBox
                labelText={"photo " + Number(index + 5)}
                imgNumber={Number(index + 5)}
                imageRef={pushPhoto}
                preFilledImage={entry}
              ></UploadBox>
            ))}
            <UploadBoxBulk
              labelText="Bulk Upload"
              imgNumber="0"
              imageRef={pushPhoto}
            ></UploadBoxBulk>
          </div>
          {error.image1 && <div className="errorBar">{error.image1}</div>}
          {error.image2 && <div className="errorBar">{error.image2}</div>}
          {error.image3 && <div className="errorBar">{error.image3}</div>}
          {error.image4 && <div className="errorBar">{error.image4}</div>}

          <div className={error.name ? "fullTextBox error" : "fullTextBox"}>
            <InputSimpleBox
              labelText="your property name* ( h1 heading )"
              placeholder="Enter Property Name"
              getRef={nameRef}
            ></InputSimpleBox>
            {error.name && <div className="errorBar">{error.name}</div>}
          </div>
          <div
            className={error.description ? "fullTextBox error" : "fullTextBox"}
          >
            <InputSimpleBox
              labelText="your property description*"
              placeholder="Enter Property Description"
              getRef={descriptionRef}
            ></InputSimpleBox>
            {error.description && (
              <div className="errorBar">{error.description}</div>
            )}
          </div>
          <div className="halfTextBox">
            <div className={error.floor ? "error" : ""}>
              <InputSimpleBox
                labelText="Floor No*"
                placeholder="Enter Property's Floor No"
                getRef={floorRef}
              ></InputSimpleBox>
              {error.floor && <div className="errorBar">{error.floor}</div>}
            </div>
            <div className={error.building ? "error" : ""}>
              <InputSimpleBox
                labelText="Building Name* "
                placeholder="Enter Property's Building Name"
                getRef={buildingRef}
              ></InputSimpleBox>
              {error.building && (
                <div className="errorBar">{error.building}</div>
              )}
            </div>
            <div className={error.building ? "error" : ""}>
              <InputSimpleBox
                labelText="Building Unit* "
                placeholder="Enter Property's Building Unit"
                getRef={bunitRef}
              ></InputSimpleBox>
              {error.bunit && <div className="errorBar">{error.buint}</div>}
            </div>
          </div>

          <div className={error.location ? "fullTextBox error" : "fullTextBox"}>
            <InputSimpleBox
              labelText="property Address*"
              placeholder="Enter Property Address"
              getRef={locationRef}
            ></InputSimpleBox>
            {error.location && <div className="errorBar">{error.location}</div>}
          </div>
          <div className="halfTextBox">
            <div className={error.pinCode ? "error" : ""}>
              <InputSimpleBox
                labelText="Pincode*"
                typeInput="number"
                placeholder="Enter Pincode"
                getRef={pincodeRef}
                onChangeInput={(e) => setPinCodeState(e.target.value)}
              ></InputSimpleBox>
              {error.pinCode && <div className="errorBar">{error.pinCode}</div>}
            </div>
            <div className={error.city ? "error" : ""}>
              <InputDropDownBox
                labelText="City* "
                getRef={cityRef}
                optionsList={allLocation
                  .filter((entry) => entry.city !== "ALL")
                  .map((entry) => {
                    return { optionLabel: entry.city, optionValue: entry.city };
                  })}
                onChangeDropDown={(value) => changeStateUsingCity(value)}
                priValue={pinCodeStateResult}
                privCode={pinCodeIDStateResult}
              ></InputDropDownBox>
              {error.city && <div className="errorBar">{error.city}</div>}
            </div>
          </div>
          <div className="fullTextBox"></div>
          <div className="halfTextBox">
            <div className={error.state ? "error" : ""}>
              <InputSimpleBox
                labelText="State*"
                placeholder="Select City"
                getRef={stateRef}
                priValue={indiaState}
                disabled="true"
              ></InputSimpleBox>
              {error.state && <div className="errorBar">{error.state}</div>}
            </div>
            <div className={error.contact ? "error" : ""}>
              <InputSimpleBox
                labelText="centre contact no. "
                placeholder="Enter Contact Number"
                getRef={contactRef}
              ></InputSimpleBox>
              {error.contact && <div className="errorBar">{error.contact}</div>}
            </div>
          </div>

          <div className="headingTitle">offerings* </div>
          <div className="offeringSection">
            <div className="innerOfferingSection">
              <div className="offeringSectionsBox">
                <div className="offeringSectionHeading">Private work space</div>
                <div className="offeringSectionAllItemsBox">
                  <div
                    className={
                      offeringState.directorCabin
                        ? "offeringSectionItem activeOfferingSectionItem"
                        : "offeringSectionItem"
                    }
                    onClick={() => updateOfferingVar("directorCabin")}
                  >
                    <div className="selectedOrNotBox"></div>
                    <div className="labelForSelectedOrNotBox">
                      Director Cabin
                    </div>
                  </div>
                  <div
                    className={
                      offeringState.suites
                        ? "offeringSectionItem activeOfferingSectionItem"
                        : "offeringSectionItem"
                    }
                    onClick={() => updateOfferingVar("suites")}
                  >
                    <div className="selectedOrNotBox"></div>
                    <div className="labelForSelectedOrNotBox">Suites</div>
                  </div>
                  <div
                    className={
                      offeringState.teamRoom
                        ? "offeringSectionItem activeOfferingSectionItem"
                        : "offeringSectionItem"
                    }
                    onClick={() => updateOfferingVar("teamRoom")}
                  >
                    <div className="selectedOrNotBox"></div>
                    <div className="labelForSelectedOrNotBox">Team Room</div>
                  </div>
                </div>
              </div>
              <div className="offeringSectionsBox">
                <div className="offeringSectionHeading">Shared workspace</div>
                <div className="offeringSectionAllItemsBox">
                  <div
                    className={
                      offeringState.dedicateDesk
                        ? "offeringSectionItem activeOfferingSectionItem"
                        : "offeringSectionItem"
                    }
                    onClick={() => updateOfferingVar("dedicateDesk")}
                  >
                    <div className="selectedOrNotBox"></div>
                    <div className="labelForSelectedOrNotBox">
                      Dedicated Desk
                    </div>
                  </div>
                  <div
                    className={
                      offeringState.dailyPass
                        ? "offeringSectionItem activeOfferingSectionItem"
                        : "offeringSectionItem"
                    }
                    onClick={() => updateOfferingVar("dailyPass")}
                  >
                    <div className="selectedOrNotBox"></div>
                    <div className="labelForSelectedOrNotBox">Daily Pass</div>
                  </div>
                </div>
                <div className="separator"></div>
              </div>
              <div className="offeringSectionsBox">
                <div className="offeringSectionHeading">Meeting Rooms</div>
                <div className="offeringSectionAllItemsBox">
                  <div
                    className={
                      offeringState.boardMeetingRoom
                        ? "offeringSectionItem activeOfferingSectionItem"
                        : "offeringSectionItem"
                    }
                    onClick={() => updateOfferingVar("boardMeetingRoom")}
                  >
                    <div className="selectedOrNotBox"></div>
                    <div className="labelForSelectedOrNotBox">
                      Board Meeting Room
                    </div>
                  </div>
                  <div
                    className={
                      offeringState.functionRoom
                        ? "offeringSectionItem activeOfferingSectionItem"
                        : "offeringSectionItem"
                    }
                    onClick={() => updateOfferingVar("functionRoom")}
                  >
                    <div className="selectedOrNotBox"></div>
                    <div className="labelForSelectedOrNotBox">
                      Function Room
                    </div>
                  </div>
                  <div
                    className={
                      offeringState.virtualRoom
                        ? "offeringSectionItem activeOfferingSectionItem"
                        : "offeringSectionItem"
                    }
                    onClick={() => updateOfferingVar("virtualRoom")}
                  >
                    <div className="selectedOrNotBox"></div>
                    <div className="labelForSelectedOrNotBox">Virtual Room</div>
                  </div>
                </div>
                <div className="separator"></div>
              </div>
              <div className="offeringSectionsBox">
                <div className="offeringSectionHeading">Virtual Offices</div>
                <div className="offeringSectionAllItemsBox">
                  <div
                    className={
                      offeringState.ce_postal
                        ? "offeringSectionItem activeOfferingSectionItem"
                        : "offeringSectionItem"
                    }
                    onClick={() => updateOfferingVar("ce_postal")}
                  >
                    <div className="selectedOrNotBox"></div>
                    <div className="labelForSelectedOrNotBox">CE_Postal</div>
                  </div>
                  <div
                    className={
                      offeringState.ce_registered
                        ? "offeringSectionItem activeOfferingSectionItem"
                        : "offeringSectionItem"
                    }
                    onClick={() => updateOfferingVar("ce_registered")}
                  >
                    <div className="selectedOrNotBox"></div>
                    <div className="labelForSelectedOrNotBox">
                      CE_Registered
                    </div>
                  </div>
                  <div
                    className={
                      offeringState.virtual_office
                        ? "offeringSectionItem activeOfferingSectionItem"
                        : "offeringSectionItem"
                    }
                    onClick={() => updateOfferingVar("virtual_office")}
                  >
                    <div className="selectedOrNotBox"></div>
                    <div className="labelForSelectedOrNotBox">
                      Virtual Office
                    </div>
                  </div>
                </div>
                <div className="separator"></div>
              </div>
              <div className="offeringSectionsBox">
                <div className="offeringSectionHeading">Event</div>
                <div className="offeringSectionAllItemsBox">
                  <div
                    className={
                      offeringState.event
                        ? "offeringSectionItem activeOfferingSectionItem"
                        : "offeringSectionItem"
                    }
                    onClick={() => updateOfferingVar("event")}
                  >
                    <div className="selectedOrNotBox"></div>
                    <div className="labelForSelectedOrNotBox">Event</div>
                  </div>
                </div>
                <div className="separator"></div>
              </div>
            </div>
          </div>

          <div className="headingTitle">Amenities* </div>
          <div className="amenitiesSection">
            <div className="offeringSectionHeading">
              Select your Available amenities
            </div>
            <div className="innerAmenitiesSection">
              {amenitiesState.map((entry) => {
                return (
                  <div
                    className={
                      entry.active
                        ? "amenitiesSectionItem activeAmenitiesSectionItem"
                        : "amenitiesSectionItem"
                    }
                    onClick={() => updateAmenitiesVar(entry.name)}
                  >
                    <div className="selectedOrNotBox"></div>
                    <div className="imgForSelectedOrNotBox">
                      <img
                        src={
                          "https://data.thecorporatedge.com/cordocs/" +
                          entry.fileData.url
                        }
                        alt=""
                      />
                    </div>
                    <div className="labelForSelectedOrNotBox">{entry.name}</div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="headingTitle">Location </div>
          <div className="halfTextBox">
            <div className={error.longitude ? "error" : ""}>
              <InputSimpleBox
                labelText="enter your location longitude*"
                placeholder="e.g. 41.233244"
                getRef={longitudeRef}
              ></InputSimpleBox>
              {error.longitude && (
                <div className="errorBar">{error.longitude}</div>
              )}
            </div>
            <div className={error.latitude ? "error" : ""}>
              <InputSimpleBox
                labelText="enter your location LATITUDE*"
                placeholder="e.g. 73.343323"
                getRef={latitudeRef}
              ></InputSimpleBox>
              {error.latitude && (
                <div className="errorBar">{error.latitude}</div>
              )}
            </div>
          </div>

          <div className="headingTitle">What's nearby? </div>
          <div className="halfTextBox">
            <div className={error.airport ? "error" : ""}>
              <InputSimpleBox
                labelText="airport"
                placeholder="Near By Airport"
                getRef={airportRef}
              ></InputSimpleBox>
              {error.airport && <div className="errorBar">{error.airport}</div>}
            </div>
            <div className={error.metroStation ? "error" : ""}>
              <InputSimpleBox
                labelText="metro station "
                placeholder="Near By Metro Station"
                getRef={metroStationRef}
              ></InputSimpleBox>
              {error.metroStation && (
                <div className="errorBar">{error.metroStation}</div>
              )}
            </div>
          </div>
          <div className="fullTextBox"></div>
          <div className="halfTextBox">
            <div className={error.spot ? "error" : ""}>
              <InputSimpleBox
                labelText="famous spot"
                placeholder="Near By Famous Spot"
                getRef={spotRef}
              ></InputSimpleBox>
              {error.spot && <div className="errorBar">{error.spot}</div>}
            </div>
            <div className={error.station ? "error" : ""}>
              <InputSimpleBox
                labelText="hotel"
                placeholder="Near By Hotel"
                getRef={stationRef}
              ></InputSimpleBox>
              {error.station && <div className="errorBar">{error.station}</div>}
            </div>
          </div>
          <div className="submitButton">
            <div className="submitButtonBox" onClick={() => uploadDataNow()}>
              <span>save all changes</span>
              <img src={changesIcon} alt="changesIcon" />
            </div>
          </div>
        </div>
      </div>
      {waitingScreen && (
        <div className="notificationFormProperty">
          <div className="mainBoxNotificationBox confirmationBox">
            <div className="iconOfNotificationBox"></div>
            <div className="textBoxOfNotificationBox">
              <div className="headingOfNotification">Please Wait</div>
              <div className="subTextOfNotification">
                We are uploading your Data
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
