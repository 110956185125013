import React, { useEffect } from "react";

import "../../styles/forms/inputSimpleBox.scss";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

export default function CkEditorBox(props) {

    useEffect(() => {
        if (!props.getRef.current) {
            props.getRef.current = ""
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    return (
        <>
            <div className="inputSimpleBox">
                <div className="editorBoxForCK">
                    <CKEditor
                        editor={Editor}
                        data={props.placeholder}
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            props.getRef.current = data
                            console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                            const data = editor.getData();
                            props.getRef.current = data
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}
                    />

                </div>
            </div>
        </>
    );
}
