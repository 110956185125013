import axios from "axios";

let allLocation = [];

export async function refreshAllLocation() {
    try {
        const response = await axios.get("https://data.thecorporatedge.com/CORManager/location/");
        allLocation = response.data;
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export function getAllLocation() {
    return allLocation;
}
